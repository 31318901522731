import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Radio,
  FormControlLabel,
  Box,
  Button
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { IAddJobComponentsProps } from "./types";
import { toast } from "react-toastify";


interface EvaluationMethod {
  title: "Source Talent" | "Expert assessment evaluation";
  description: string;
  code: string;
}

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== "selected",
})<{ selected: boolean }>(({ theme, selected }) => ({
  width: "305px",
  cursor: "pointer",
  borderRadius: "12px",
  border: selected
    ? `2px solid ${theme.palette.primary.main}`
    : "1px solid #D1D5DB",
  transition: "all 0.3s ease",
  boxShadow: selected
    ? "0 0 8px rgba(33, 150, 243, 0.3)"
    : "0px 1px 3px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    border: `2px solid ${theme.palette.primary.main}`,
  },
}));

const CardContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  gap: "24px",
  flexWrap: "wrap",
  marginTop: "24px",
});

const StyledFormControlLabel = styled(FormControlLabel)({
  margin: 0,
  alignItems: "flex-start",
});

const EvaluationType: React.FC<IAddJobComponentsProps> = (props) => {
  const [selectedMethod, setSelectedMethod] = useState<string>("");
  const { expertConfig } = useSelector((state: RootState) => state.auth);

  const IsIASMode = expertConfig?.permissions?.ias?.view || false;

  const methods: EvaluationMethod[] = [
    {
      title: "Expert assessment evaluation",
      code: "EXPERT_EVAL",
      description:
        "Publish a job and leverage expert-driven evaluation to assess candidate profiles accurately.",
    },
    {
      title: "Source Talent",
      code: "SOURCE_TALENT",
      description:
        "Publish a job and leverage AI-driven job bar evaluation scores to assess talent profiles accurately.",
    },
  ];

  useEffect(() => {
    if (IsIASMode){
      handleSelect("EXPERT_EVAL");
    }
    props.setLoading(false);
  }, [expertConfig]);  

  const handleSelect = (method: string) => {
    setSelectedMethod(method);
    props.handleSetJobId(method, "jobDetails")
  };

  const handleNext = () => {
    if (selectedMethod !== "EXPERT_EVAL" && selectedMethod !== "SOURCE_TALENT"){
      toast.error("Please select How you want to evaluate the candidates?");
    } else {
      handleSelect(selectedMethod);
    }
  }

  return (
    <Box sx={{ marginTop: "200px", marginRight: "100px" }}>
      <Box sx={{ width: "500px", margin: "auto", textAlign: "center" }}>
        <Typography fontSize="16px" fontFamily="inter" fontWeight="500">
          Select how you want to evaluate the candidates?
        </Typography>
        <Typography variant="body2" color="#8899A8" sx={{ mt: 1, mb: 6 }}>
          Choose your preferred method, such as AI scoring, expert assessments,
          or custom criteria.
        </Typography>
      </Box>
      <CardContainer>
        {methods.map((method) => (
          <StyledCard
            key={method.title}
            selected={selectedMethod === method.code}
            onClick={() => handleSelect(method.code)}
          >
            <CardContent sx={{}}>
              <StyledFormControlLabel
                value={method.title}
                control={
                  <Radio
                    checked={selectedMethod === method.code}
                    size="small"
                    style={{ padding: "1px" }}
                  />
                }
                label={
                  <Box marginLeft="5px">
                    <Typography
                      fontSize="15px"
                      fontFamily={"inter"}
                      fontWeight="500"
                      textAlign={"start"}
                      marginBottom="5px"
                    >
                      {method.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      textAlign="start"
                    >
                      {method.description}
                    </Typography>
                  </Box>
                }
              />
            </CardContent>
            <Button
              onClick={handleNext}
              ref={props.submitBtnRef}
              style={{ display: "none" }}
            >
              Save Job Bar
            </Button>
          </StyledCard>
        ))}
      </CardContainer>
    </Box>
  );
};

export default EvaluationType;
