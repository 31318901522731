import { Box, Button, ButtonGroup, IconButton, InputBase, Menu, MenuItem, Paper, Tab, Tabs } from "@mui/material";
import { SearchAlt2 } from '@styled-icons/boxicons-regular/SearchAlt2';
import { Globe } from "@styled-icons/entypo/Globe";
import { Lock } from "@styled-icons/entypo/Lock";
import { getJobAnalytics, getJobDetails, getJobFilters, getResultForJobSearch, updateJobRanking } from "actions/hiringManager/JobManagement/jobManagementActions";
import { reOrderJobSearchResult, resetFilters, setJobSearchFilter, unsetJobDetails, updateJobAtIndex } from "actions/hiringManager/JobManagement/jobManagementSlice";
import { getLocationInOptionDataFormat, JOB_ACCESS_CONSTANT } from 'containers/HiringManager/Jobs.util';
import { debounce, isEmpty, isNumber } from "lodash";
import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { RootState } from "store";
import styled from "styled-components";
import { AttributeEntity, CreateJobRequest, DateFilterListType, FilterOptionsType, JobAccessFilterType, JobFilterRequestTypeOmit, JobSearchRequestTypeOmit, JobSearchResult, MoveActionType } from "types/JobManagement";
import { getDateTimeInLocalFormat, getRandomString } from "utilities/commonUtils";
import JobAdditionalInfo from "./Components/JobAdditionalInfo";
import CreateJobModalContainer from "./Containers/CreateJobModalContainer";
import { MoreVerticalOutline } from '@styled-icons/evaicons-outline/MoreVerticalOutline';
import { History } from '@styled-icons/boxicons-regular/History';
import { useHistory } from 'react-router';
import { ChevronDoubleDown } from '@styled-icons/heroicons-solid/ChevronDoubleDown';
import { ChevronDoubleUp } from '@styled-icons/heroicons-solid/ChevronDoubleUp';
import { OverlayLoader } from "components/Common/Loader/loaderWithOverlay";
import MemoisedImage from "components/MemoisedImage";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Edit } from '@styled-icons/fluentui-system-filled/Edit';
import { ToggleLeft } from '@styled-icons/boxicons-regular/ToggleLeft';
import { FileTextOutline } from '@styled-icons/evaicons-outline/FileTextOutline';
import { Tools } from '@styled-icons/entypo/Tools';
import TableWithDnd, { TableColumn } from "components/TableWithDnd/TableWithDnd";
import { jobsManagementService } from "services/jobManagement";
import { toast } from "react-toastify";
import CapabilitiesModal from "./Components/CapabilitiesModal/CapabilitiesModal";
import { BASE_API_URL, DEFAULT_TOKEN } from "utilities/constants";
import { fetchCampaignAndAnalytics } from "actions/campaign/campaignActions";
import { Link } from 'react-router-dom';

export const JOB_SEARCH_PRIMARY_ICONS_COLOR = "#1876d2"
const DEFAULT_JOBS_COUNT = { totalActive: 0, totalInactive: 0 };

type IProps = {
    networkJobs?: boolean;
}

const ACTION_BUTTON_STYLE = {
    width: '20px',
    cursor: 'pointer',
    color: '#325cd5'
}

const StyledWrapper = styled.div`
    .mui-icon-buttons {
        padding: 0.2rem;
    }
    .pointer-event-none {
        pointer-events: none;
    }
    .search-candidate-input {
        box-shadow: none;
        outline: 1px solid #bfd1ff;
        border-radius: 3px;
    }
    .search-candidate-input.hover {
        outline-width: 2px;
    }
    .tabs-container {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }
`

const StyledMoreAction = styled.div`
    font-size: 16px;
    display: flex;
    align-items: center;
`

const CellValueContainer = styled.div<{ primaryColor: string }>`
    .job-title-style: hover {
        color: ${props => props.primaryColor};
    }
    .styled-company-logo {
        width: 20px;
        display: block;
    }
    
`
function getDateRange(title: DateFilterListType['title']): { startDate: string, endDate: string } {
    const now = new Date();
    let startDate: Date;
    let endDate: Date;

    switch (title) {
        case "Last 7 days":
            endDate = new Date(now);

            startDate = new Date(now);
            startDate.setDate(now.getDate() - 6);
            break;

        case "Current Week":
            startDate = new Date(now);
            startDate.setDate(now.getDate() - now.getDay());

            endDate = new Date(now);
            endDate.setDate(startDate.getDate() + 6);
            break;

        case "Previous Week":
            endDate = new Date(now);
            const lastSunday = endDate.getDate() - endDate.getDay() - 1;
            endDate.setDate(lastSunday);

            startDate = new Date(endDate);
            startDate.setDate(endDate.getDate() - 6);
            break;

        case "Last 30 days":
            endDate = new Date(now);

            startDate = new Date(now);
            startDate.setDate(now.getDate() - 29);
            break;

        case "Current Month":
            startDate = new Date(now.getFullYear(), now.getMonth(), 1);

            endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
            break;

        case "Previous Month":
            startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);

            endDate = new Date(now.getFullYear(), now.getMonth(), 0);
            break;

        default:
            throw new Error("Invalid title provided.");
    }

    const formatDate = (date: Date): string => {
        const padZero = (num: number) => num.toString().padStart(2, '0');
        return `${padZero(date.getMonth() + 1)}/${padZero(date.getDate())}/${date.getFullYear()} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;
    };

    startDate.setHours(0, 0, 0);
    endDate.setHours(23, 59, 59);
    return {
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
    };
}




const getColumnsAndFilters = (
    filterOptions: FilterOptionsType,
    jobSearchFilter: JobSearchRequestTypeOmit,
    onFilter: (key: string, filter: any) => void,
    history: any,
): TableColumn<JobSearchResult>[] => {
    const columns: TableColumn<JobSearchResult>[] = [
        {
            id: 'jobTitle', label: "Job Title",
            format: (value, rowData, selected) => {
                const handleClick = () => {
                    history.push(`/job-pipeline?jobId=${rowData.jobId}`);
                };

                const displayValue = typeof value === 'string' 
                    ? (value.length > 53 ? `${value.slice(0, 50)}...` : value) 
                    : value;

                return (
                    <CellValueContainer primaryColor={JOB_SEARCH_PRIMARY_ICONS_COLOR} >
                        <div 
                            
                            title={typeof value === 'string' && value.length > 20 ? value : ""}
                            onClick={handleClick}
                        >
                            <u className="font-weight-bold cursor-pointer job-title-style">
                                {displayValue}
                            </u>
                        </div>
                    </CellValueContainer>
                );
            },
            minWidth: "15rem",
            filterObj: filterOptions?.jobDetails?.allowFilter ? {
                filterType: 'checkBoxes',
                options: filterOptions.jobDetails.list.map(op => ({ id: op.id ?? getRandomString(), title: op.title })),
                selectedFilter: jobSearchFilter.jobIds ?? [],
                onFilter: (query) => onFilter('jobIds', query)
            } : undefined
        },
        {
            id: 'companyInfo', label: "Company",
            format: (value, rowData, selected) => {
                const companyName = rowData.companyInfo?.companyName;
                const displayValue = typeof companyName === 'string'
                    ? (companyName.length > 33 ? `${companyName.slice(0, 30)}...` : companyName)
                    : companyName;

                return (
                    <CellValueContainer primaryColor={JOB_SEARCH_PRIMARY_ICONS_COLOR} >
                        <div 
                           
                            title={typeof companyName === 'string' && companyName.length > 20 ? companyName : ""}
                        >
                            <label className={selected ? "" : "text-muted"}>{displayValue}</label>
                        </div>
                    </CellValueContainer>
                );
            },
            filterObj: filterOptions?.companyDetails?.allowFilter ? {
                filterType: 'checkBoxes',
                options: filterOptions.companyDetails.list.map(op => ({ id: op.id ?? getRandomString(), title: op.title })),
                selectedFilter: jobSearchFilter.companyIds ?? [],
                onFilter: (query) => onFilter('companyIds', query)
            } : undefined
        },
        {
            id: 'owner', label: "Owner",
            format: (value, rowData, selected) => {
                const ownerName = rowData.expertInfo.fullname;
                const displayValue = typeof ownerName === 'string' 
                    ? (ownerName.length > 33 ? `${ownerName.slice(0, 30)}...` : ownerName) 
                    : ownerName;

                return (
                    <CellValueContainer primaryColor={JOB_SEARCH_PRIMARY_ICONS_COLOR} >
                        <div className="d-flex align-items-center justify-content-between">
                            <Box sx={{display:"flex", justifyContent:"space-between"}}>
                                {rowData.companyInfo?.companyLogo && 
                                    <MemoisedImage 
                                        imgUrl={rowData.companyInfo?.companyLogo} 
                                        alt="" 
                                        className="styled-company-logo" 
                                    />
                                }
                                <Box sx={{marginLeft:"10px"}}>
                                <span title={ownerName}>
                                    {displayValue}
                                </span>
                                </Box>
                            </Box>
                            <div>
                                {rowData.jobAccess === JOB_ACCESS_CONSTANT.PUBLIC 
                                    ? <Globe color="forestgreen" className="styled-company-logo" /> 
                                    : <Lock color="gold" className="styled-company-logo" />}
                            </div>
                        </div>
                    </CellValueContainer>
                );
            },
            minWidth: "10rem",
            filterObj: filterOptions?.ownerDetails?.allowFilter ? {
                filterType: 'checkBoxes',
                options: filterOptions.ownerDetails.list.map(op => ({ id: op.id ?? getRandomString(), title: op.title })),
                selectedFilter: jobSearchFilter.employeeIds ?? [],
                onFilter: (query) => onFilter('employeeIds', query)
            } : undefined
        },
        {
            id: 'openPositions', label: "Open Positions",
            format: (value, rowData, selected) => {
                return (
                    <CellValueContainer primaryColor={JOB_SEARCH_PRIMARY_ICONS_COLOR} >
                        <label className={`${selected ? "" : "text-muted"} ml-1`}>
                            {(rowData.candidatesCount?.finalized ?? 0) + "/" + (value ?? 0)}
                        </label>
                    </CellValueContainer>
                );
            },
        },
        {
            id: 'createdAt', label: "Created",
            format: (value, rowData, selected) => {
                return (
                    <CellValueContainer primaryColor={JOB_SEARCH_PRIMARY_ICONS_COLOR} >
                        <label className={selected ? "" : "text-muted"}>
                            {getDateTimeInLocalFormat(new Date(value))}
                        </label>
                    </CellValueContainer>
                );
            },
            filterObj: filterOptions?.dateRangeDetails?.allowFilter ? {
                filterType: 'date',
                options: filterOptions.dateRangeDetails.list.map(op => ({
                    id: op.title,
                    title: op.title
                })),
                selectedFilter: jobSearchFilter?.dateFilter?.title ? jobSearchFilter?.dateFilter?.title : "",
                onFilter: (query) => {
                    try {
                        const dateRange = getDateRange(query);
                        onFilter('dateFilter', { ...dateRange, title: query });
                    } catch (e) {
                        onFilter('dateFilter', null);
                    }
                }
            } : undefined
        },
        {
            id: 'comments', label: "Comments/Tasks",
            format: (value, rowData, selected) => {
                return (
                    <CellValueContainer primaryColor={JOB_SEARCH_PRIMARY_ICONS_COLOR} >
                        <label className={selected ? "" : "text-muted"}>{value ? value : "--"}</label>
                    </CellValueContainer>
                );
            }
        },
    ];
    return columns;
};


const ActionButtons = ({
    handleMoreClick,
    handleShowCapabilitiesClick,
    hideMoreAction,
    handleEditCandidatesClick,
    saveScrollAndPagination
}: {
    handleMoreClick: (el: HTMLDivElement) => void;
    handleShowCapabilitiesClick: () => void;
    handleEditCandidatesClick: () => void;
    hideMoreAction?: boolean,
    saveScrollAndPagination: () => void;
}) => {
    return (
        <>
            <div className='px-2 d-inline'
                onClick={(e) => {
                    e.stopPropagation();
                    saveScrollAndPagination();
                    handleEditCandidatesClick();
                }}>
                <OverlayTrigger
                    placement="bottom"
                    overlay={
                        <Tooltip
                            id={'job-search-check-candidates-action'}
                        >
                            Edit candidates
                        </Tooltip>
                    }
                >
                    <Tools style={{ ...ACTION_BUTTON_STYLE }} />
                </OverlayTrigger>
            </div>
            <div className='px-2 d-inline border-left'
                onClick={(e) => {
                    e.stopPropagation();
                    saveScrollAndPagination();                    
                    handleShowCapabilitiesClick();
                }}>
                <OverlayTrigger
                    placement="bottom"
                    overlay={
                        <Tooltip
                            id={'job-search-job-details-action'}
                        >
                            View Capabilities
                        </Tooltip>
                    }
                >
                    <FileTextOutline style={{ ...ACTION_BUTTON_STYLE }} />
                </OverlayTrigger>
            </div>
            {/* <div className='px-2 d-inline border-left'
                onClick={(e) => {
                    e.stopPropagation();
                }}>
                <OverlayTrigger
                    placement="bottom"
                    overlay={
                        <Tooltip
                            id={'job-search-history-action'}
                        >
                            View Job History
                        </Tooltip>
                    }
                >
                    <History style={{ ...ACTION_BUTTON_STYLE }} />
                </OverlayTrigger>
            </div> */}
            {!hideMoreAction && <div className='pl-2 d-inline border-left'
                onClick={(e) => {
                    e.stopPropagation();
                    handleMoreClick(e.currentTarget);
                }}>
                <OverlayTrigger
                    placement="bottom"
                    overlay={
                        <Tooltip
                            id={'job-search-more-action'}
                        >
                            More
                        </Tooltip>
                    }
                >
                    <MoreVerticalOutline style={{ ...ACTION_BUTTON_STYLE }} />
                </OverlayTrigger>
            </div>}
        </>
    )
}

const JobSearch = (props: IProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const expertId = useSelector((state: RootState) => state.auth.user.expertId);
    const { loading, screenBlockingLoader, loadingAnalytics, jobSearchResult, totalJobs, jobSearchFilter, filterOptions, jobDetail } = useSelector((state: RootState) => state.jobManagement);
    const { campaignData, screenBlockingLoaderView } = useSelector((state: RootState) => state.campaign);
    const [showCreateJob, setShowCreateJob] = useState<boolean>(false);
    const [moreActionBtn, setMoreActionBtn] = useState<{ el: HTMLDivElement, jobId: string, status: boolean} | null>(null);
    const [jobsCount, setJobsCount] = useState<{ totalActive: number, totalInactive: number }>(DEFAULT_JOBS_COUNT);
    const [localLoader, setLocalLoader] = useState<boolean>(false);
    const [status, setStatus] = useState<string | null>(null);
    const [polling, setPolling] = useState(false);
    const [confirmBoxEl, setConfirmBoxEl] = useState<{
        el: HTMLElement,
        confirmAction: Function,
        cancelAction: Function,
    } | null>(null);
    const [jobCapabilities, setJobCapabilities] = useState<null | AttributeEntity[]>(null);
    const debounceCandidateData = useCallback(debounce(
        (filter: JobSearchRequestTypeOmit) => { fetchJobs(filter) },
        500), [expertId]);
    const [isJobListLoaded, setIsJobListLoaded] = useState(false);

    const fetchJobsCount = () => {
        jobsManagementService.getJobsCount(expertId)
            .then(res => setJobsCount({ totalActive: res.output.totalActive, totalInactive: res.output.totalInactive }))
            .catch(() => setJobsCount(DEFAULT_JOBS_COUNT))
    }

    const fetchJobs = (filter: JobSearchRequestTypeOmit) => {
        dispatch(getResultForJobSearch({ ...filter, expertId, publicJobs: props.networkJobs }));
    }

    const fetchJobsFilter = (payload: JobFilterRequestTypeOmit) => {
        dispatch(resetFilters());
        dispatch(getJobFilters({ ...payload, expertId }));
    }

    const handleTabSelect = (event: React.SyntheticEvent, newValue: string) => {
        const updatedFilter = { ...jobSearchFilter, activeJobs: newValue === "true", skipCount: 0 };
        dispatch(setJobSearchFilter(updatedFilter));
        fetchJobs(updatedFilter);
        fetchJobsFilter(updatedFilter);
    };
    console.log(screenBlockingLoaderView, "Progress");
    
    const handleAccessFilterSelect = (filter: JobAccessFilterType) => {
        const updatedFilter = { ...jobSearchFilter, jobAccess: filter, skipCount: 0 };
        dispatch(setJobSearchFilter(updatedFilter));
        fetchJobs(updatedFilter);
        fetchJobsFilter(updatedFilter);
    }

    const handleFilterChange = (query: string) => {
        const updatedFilter = { ...jobSearchFilter, textToSearch: query };
        dispatch(setJobSearchFilter(updatedFilter));
        debounceCandidateData(updatedFilter);
    }

    const handlePaginationChange = (paginationFilter: { count: number, newPage: number }) => {
        const updatedFilter = {
            ...jobSearchFilter,
            count: paginationFilter.count,
            skipCount: paginationFilter.newPage * paginationFilter.count
        };
        dispatch(setJobSearchFilter(updatedFilter));
        fetchJobs({ ...updatedFilter });
    }

    const attachAdditionalInfoToRows = () => {
        return jobSearchResult.map((job, idx) => {
            return {
                ...job,
                additionalInfoComponent: <JobAdditionalInfo
                    jobAnalytics={job.jobAnalytics}
                    employmentType={job.employmentType}
                    equity={job.equity}
                    jobType={job.jobType}
                    locations={job.locations}
                    mutedBorder={idx % 2 === 0}
                    maxSalary={job.maxSalary}
                    minSalary={job.minSalary}
                    loadingAnalytics={loadingAnalytics[job.jobId]}
                    numberOfDaysOfficeEveryWeek={job.numberOfDaysOfficeEveryWeek}
                    startTime={job.startTime}
                    endTime={job.endTime}
                    agencyRewardFixedAmount={job.agencyRewardFixedAmount}
                    agencyRewardPercentageOfAnnualSalary={job.agencyRewardPercentageOfAnnualSalary}
                    agencyRewardPercentageOfHourlyRate={job.agencyRewardPercentageOfHourlyRate}
                    currency={job.currency}
                />
            }
        })
    }

    const onFilter = (key: string, value: any) => {
        const updatedFilter = { ...jobSearchFilter, [key]: value };
        dispatch(setJobSearchFilter(updatedFilter));
        debounceCandidateData(updatedFilter);
    }

    const handleExpandJob = (jobId: string) => {
        const selectedJob = jobSearchResult.find((job) => job.jobId === jobId);
        if (selectedJob?.jobAnalytics.length === 0) {
            dispatch(getJobAnalytics({
                expertId,
                jobId
            }))
        }
    }

    const handleMoveAction = (action: MoveActionType) => {
        if (moreActionBtn?.jobId) {
            dispatch(updateJobRanking({
                expertId: expertId,
                jobId: moreActionBtn?.jobId,
                moveAction: action
            }));
            fetchJobs(jobSearchFilter);
        }
    }

    const handleReOrderJobSearchResult = (movedRowNewIdx: number, movedRowIdx: number) => {
        if (movedRowNewIdx === movedRowIdx) {
            return;
        }
        let minRank = jobSearchFilter.skipCount + 1;
        dispatch(reOrderJobSearchResult({
            movedRowIdx: movedRowIdx,
            movedRowNewIdx: movedRowNewIdx,
            minRank: minRank
        }));
        dispatch(updateJobRanking({
            expertId: expertId,
            jobId: jobSearchResult[movedRowIdx].jobId,
            rank: minRank + movedRowNewIdx
        }));
    }

    const allowDragAndDrop = (): boolean => {
        if (props.networkJobs) {
            return false;
        }
        const dndNotAllowedForFilters: Array<keyof JobSearchRequestTypeOmit> = ['textToSearch', 'employeeIds', 'jobIds', 'companyIds', 'dateFilter'];
        for (let el of dndNotAllowedForFilters) {
            if (!isEmpty(jobSearchFilter[el])) {
                return false;
            }
        }
        if (!jobSearchFilter['activeJobs']) {
            return false;
        }
        if (jobSearchFilter['jobAccess'] !== '') {
            return false;
        }
        return true;
    }

    const handleAfterJobCreate = () => {
        handlePaginationChange({ count: jobSearchFilter.count, newPage: 0 });
        setShowCreateJob(false);
    }

    const handleAfterJobEdit = (updatedJobDetails: CreateJobRequest) => {
        fetchJobs(jobSearchFilter);
        dispatch(unsetJobDetails());
    }

    const fetchJobDetails = (jobId: string) => {
        dispatch(getJobDetails({
            jobId,
            expertId
        }))
    }

    const handleDeactivateClick = (el: any) => {
        setConfirmBoxEl({
            el: el.currentTarget,
            cancelAction: () => {
                setConfirmBoxEl(null);
            },
            confirmAction: () => {
                deactivateJob(moreActionBtn?.jobId!);
                setMoreActionBtn(null);
            }
        });
    }

    const deactivateJob = async (jobId: string) => {
        setLocalLoader(true);
        await jobsManagementService.updateJobStatus({
            active: false,
            expertId,
            jobId
        }).then((res) => {
            toast.success("Job successfully deactivated");
            fetchJobs(jobSearchFilter);
        }).catch((e) => {
            toast.error(e.message)
        }).finally(() => setLocalLoader(false))
    }

    const handleShowJobCapabilities = (jobId: string) => {
        const job = jobSearchResult.find(result => result.jobId === jobId);
        setJobCapabilities(job?.attributes!);
    }

    const handleEditCandidatesClick = (jobId: string) => {
        history.push(`/job-pipeline?jobId=${jobId}`)
    }

    const handleViewCampaignClick = async (jobId: string) => {
      const result = await dispatch(fetchCampaignAndAnalytics({ jobId, expertId, token: "123"}));
      history.push(`/campaign?jobId=${jobId}`, { campaignData});
    }

    const createCampaign = async (jobId: string, expertId: string) => {
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${DEFAULT_TOKEN}`,
          };
          try{
           setLocalLoader(true);
        const res = await fetch(`${BASE_API_URL}/evaluationPlatform/expert/startJobCampaign`, {
            method: 'POST',
            headers,
            body: JSON.stringify({
                jobId: jobId,
                expertId: expertId,
                token: DEFAULT_TOKEN,
            }),
        });
        if (!res.ok) {
            const errorData = await res.json();
            toast.error(errorData.apiMessage);
            throw new Error(`Error: ${res.status}, ${errorData.message || "Unknown error"}`);
        }
        setLocalLoader(false);
        const data = await res.json();
        toast.success(data.apiMessage);
        pollCampaignProgress(jobId);
          } catch(error) {
            setLocalLoader(false);
            console.error("Error creating campaign");
          }
    }

  const pollCampaignProgress = (jobId: string) => {
    setPolling(true);
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${DEFAULT_TOKEN}`,
      };
    const intervalId = setInterval(async () => {
      try {
        
        const res = await fetch(`${BASE_API_URL}/evaluationPlatform/expert/campaign/getSetupProgress`, {
            method: 'POST',
            headers,
            body: JSON.stringify({
                jobId,
                expertId,
                token: DEFAULT_TOKEN,
              }),
        });
        const data = await res.json();
        if (data.output.status === 'COMPLETED') {
          toast.success('Campaign created successfully!');
          clearInterval(intervalId);
          setPolling(false);
          fetchJobs({ 
            activeJobs: true, 
            jobAccess: '', 
            count: 10, 
            skipCount: 0 
          });
        } else if (data.output.status === 'FAILED') {
          toast.error(`Campaign creation failed! ${data.output.message}`);
          clearInterval(intervalId);
          setPolling(false);
        } else if (data.output.status === 'STARTED') {
            setStatus('STARTED');
        }
      } catch (error) {
        console.error('Error polling campaign progress:', error);
        clearInterval(intervalId);
        setPolling(false);
      }
    }, 3000);
  };

  const getCampaignAction = (job: any) => {
    const campaigns = job.campaignDetails?.campaigns ?? [];
    const campaignCreationStatus = job.campaignDetails?.campaignCreationStatus;

    if (campaigns.length === 0) {
        return 'create';
      }
      if (campaignCreationStatus === 'STARTED') {
        return 'view';
      }
      return 'view';
  };

  const tableScrollRef = useRef<HTMLDivElement>(null);

  const saveSession = (key: string, value: any) => {
    const routePrefix = window.location.pathname.replace(/\//g, "");
    sessionStorage.setItem(routePrefix + ":" + key, value);
  }

  const getSession = (key: string) => {
    const routePrefix = window.location.pathname.replace(/\//g, "");
    return sessionStorage.getItem(routePrefix + ":" + key);
  }

  const removeSession = (key: string) => {
    const routePrefix = window.location.pathname.replace(/\//g, "");
    sessionStorage.removeItem(routePrefix + ":" + key);
  }

  const saveScrollAndPagination = () => {
    const scrollPosition = tableScrollRef.current?.scrollTop || 0;
    const currentPage = jobSearchFilter.skipCount / jobSearchFilter.count;

    saveSession("scroll", String(scrollPosition));
    saveSession("currentpage", String(currentPage));
  }

    // useEffect(() => {
    //     if (expertId) {
    //         fetchJobs(jobSearchFilter);
    //         fetchJobsFilter(jobSearchFilter);
    //         fetchJobsCount();
    //     }
    // }, [expertId])

    useEffect(() => {
        if (expertId) {
            const routePrefix = window.location.pathname.replace(/\//g, "");
            const savedPage = getSession("currentpage");
    
            if (savedPage) {
                const updatedFilter = {
                    ...jobSearchFilter,
                    skipCount: Number(savedPage) * jobSearchFilter.count,
                };
                dispatch(setJobSearchFilter(updatedFilter));
                fetchJobs(updatedFilter);
                removeSession("currentpage");
                fetchJobsCount();
            } else {
                fetchJobs(jobSearchFilter);
                fetchJobsFilter(jobSearchFilter);
                fetchJobsCount();
            }
        }
    }, [expertId, jobSearchFilter]);
    
    useEffect(() => {
        const savedScroll = getSession("scroll");

        if (!loading && savedScroll && tableScrollRef.current) {
            setTimeout(() => {
                tableScrollRef.current?.scrollTo(0, Number(savedScroll));
                removeSession("scroll");
            }, 3800);
        }
    }, [loading]);

    return (
        <StyledWrapper>
            <OverlayLoader loading={screenBlockingLoader || localLoader || screenBlockingLoaderView} />
            <Box sx={{ width: '100%' }}>
                <Tabs
                    value={String(jobSearchFilter.activeJobs)}
                    onChange={handleTabSelect}
                    className="tabs-container"
                >
                    <Tab value={'true'} label={`Active Jobs (${jobsCount.totalActive})`} />
                    {!props.networkJobs && <Tab value={'false'} label={`Closed Jobs (${jobsCount.totalInactive})`} />}
                </Tabs>
            </Box>
            <div className="d-flex justify-content-between mt-4">
                <div className="d-flex">
                    <Paper
                        className="search-candidate-input mr-3"
                        component="form"
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                    >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search by Candidate name"
                            onChange={(e) => handleFilterChange(e.target.value)}
                        />
                        <IconButton type="button" className="pointer-event-none mui-icon-buttons">
                            {loading ? <ClipLoader
                                color='#315CD5'
                                size={20}
                                loading={loading}
                                speedMultiplier={4}
                            /> :
                                <SearchAlt2 color={JOB_SEARCH_PRIMARY_ICONS_COLOR} width={'25px'} className="user-select-none" />}
                        </IconButton>
                    </Paper>
                    {!props.networkJobs && <ButtonGroup variant="outlined">
                        <Button
                            onClick={() => handleAccessFilterSelect('')}
                            variant={jobSearchFilter.jobAccess === '' ? 'contained' : 'outlined'}
                        >
                            ALL
                        </Button>
                        <Button
                            onClick={() => handleAccessFilterSelect('PRIVATE')}
                            variant={jobSearchFilter.jobAccess === 'PRIVATE' ? 'contained' : 'outlined'}
                        >
                            <Lock width={'20px'} />
                        </Button>
                        <Button
                            onClick={() => handleAccessFilterSelect('PUBLIC')}
                            variant={jobSearchFilter.jobAccess === 'PUBLIC' ? 'contained' : 'outlined'}
                        >
                            <Globe width={'20px'} />
                        </Button>
                    </ButtonGroup>}
                </div>
                {!props.networkJobs && <div>
                    <Button variant='contained' onClick={() => setShowCreateJob(true)}>
                        Add a Job
                    </Button>&nbsp;
                    <Button variant='contained' onClick={() => history.push(`/add-job`)}>{'Add a Job (New)'}</Button>
                </div>}
            </div>
            <div className="mt-4">
                <TableWithDnd
                    scrollRef={tableScrollRef}
                    maxHeight="65vh"
                    columns={useMemo(() => getColumnsAndFilters(filterOptions, jobSearchFilter, onFilter, history), [filterOptions, jobSearchFilter])}
                    rows={useMemo(() => attachAdditionalInfoToRows(), [jobSearchResult, loadingAnalytics])}
                    totalRows={totalJobs}
                    noRowMsg={'No Jobs'}
                    rowsPerPageCount={jobSearchFilter.count}
                    currPage={jobSearchFilter.skipCount / jobSearchFilter.count}
                    handlePaginationChange={handlePaginationChange}
                    handleExpandRow={handleExpandJob}
                    reOrderRows={handleReOrderJobSearchResult}
                    rowDraggable={useMemo(() => allowDragAndDrop(), [jobSearchFilter, props.networkJobs])}
                    actionButtons={(row) => <ActionButtons
                        handleMoreClick={(el) => setMoreActionBtn({
                            el: el,
                            jobId: row.jobId,
                            status: row.active,
                        })}
                        handleShowCapabilitiesClick={() => handleShowJobCapabilities(row.id)}
                        hideMoreAction={props.networkJobs}
                        handleEditCandidatesClick={() => handleEditCandidatesClick(row.jobId)}
                        saveScrollAndPagination={saveScrollAndPagination}

                    />}
                />
            </div>

            {showCreateJob && <CreateJobModalContainer
                setShowCreateJob={setShowCreateJob}
                handleAfterJobSave={handleAfterJobCreate}
                mode="CREATE_JOB"
            />}

            {jobDetail && <CreateJobModalContainer
                setShowCreateJob={() => dispatch(unsetJobDetails())}
                handleAfterJobSave={handleAfterJobEdit}
                mode="EDIT_JOB"
                jobDetails={{
                    ...jobDetail as any
                }}
            />}

            {jobCapabilities !== null && <CapabilitiesModal
                showScore
                attributes={jobCapabilities}
                show={Boolean(jobCapabilities)}
                handleClose={() => setJobCapabilities(null)}
                isJobsCard
            />}

            {/* More action popover */}
            <Menu
                anchorEl={confirmBoxEl?.el}
                open={Boolean(confirmBoxEl)}
                onClose={() => setConfirmBoxEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                onClick={(e) => {
                    setConfirmBoxEl(null);
                }}
            >
                <div className="px-3 text-center">
                    <span className="d-block">Are you sure you want to proceed with this action?</span>
                    <Button variant="text" onClick={() => confirmBoxEl?.confirmAction()}>Yes</Button>
                    <Button variant="text" onClick={() => confirmBoxEl?.cancelAction()}>No</Button>
                </div>
            </Menu>
            <Menu
                anchorEl={moreActionBtn?.el}
                open={Boolean(moreActionBtn)}
                onClose={() => {
                    setMoreActionBtn(null);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                {jobSearchFilter.activeJobs && <>
                    <MenuItem
                        onClick={() => {
                            handleMoveAction('MOVE_TOP');
                            setMoreActionBtn(null);
                        }}
                    >
                        <StyledMoreAction>
                            <ChevronDoubleUp width={'20px'} color='#6d6d6d' />
                            <label className="ml-2 mb-0">Move to Top of List</label>
                        </StyledMoreAction>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleMoveAction('MOVE_BOTTOM');
                            setMoreActionBtn(null);
                        }}
                    >
                        <StyledMoreAction>
                            <ChevronDoubleDown width={'20px'} color='#6d6d6d' />
                            <label className="ml-2 mb-0">Move to Bottom of List</label>
                        </StyledMoreAction>
                    </MenuItem>
                </>}
                {moreActionBtn && moreActionBtn.status && (() => {
                     const job = jobSearchResult.find((job) => job.id === moreActionBtn.jobId);
                     if (!job) return null;
               
                     const action = getCampaignAction(job);
                     return (action === 'create' && status !== 'STARTED')? (
                <MenuItem
                    onClick={() => {
                        saveScrollAndPagination();
                        createCampaign(moreActionBtn?.jobId!, expertId);
                    }}
                >
                    <StyledMoreAction>
                        <Edit width={'20px'} color='#6d6d6d' />
                        <label className="ml-2 mb-0">Create Campaign</label>
                    </StyledMoreAction>
                </MenuItem>
                ) : (
                <MenuItem
                    onClick={(e) => {
                        e.stopPropagation();
                        saveScrollAndPagination();
                        handleViewCampaignClick(moreActionBtn?.jobId!)
                    }}
                >
                    <StyledMoreAction>
                        <Edit width={'20px'} color='#6d6d6d' />
                        <label className="ml-2 mb-0">View Campaign</label>
                    </StyledMoreAction>
                </MenuItem>
                );
            })()}
                <MenuItem
                    onClick={() => {
                        saveScrollAndPagination();
                        fetchJobDetails(moreActionBtn?.jobId!);
                        setMoreActionBtn(null);
                    }}
                >
                    <StyledMoreAction>
                        <Edit width={'20px'} color='#6d6d6d' />
                        <label className="ml-2 mb-0">Edit Job details</label>
                    </StyledMoreAction>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        saveScrollAndPagination();
                        history.push(`/edit-job?jobId=${moreActionBtn?.jobId!}`);
                    }}
                >
                    <StyledMoreAction>
                        <Edit width={'20px'} color='#6d6d6d' />
                        <label className="ml-2 mb-0">Edit Job details (New)</label>
                    </StyledMoreAction>
                </MenuItem>
                {jobSearchFilter.activeJobs && <MenuItem
                    onClick={(e) => handleDeactivateClick(e)}
                >
                    <StyledMoreAction>
                        <ToggleLeft width={'20px'} color='#e34445' />
                        <label className="ml-2 mb-0">Deactivate Job</label>
                    </StyledMoreAction>
                </MenuItem>}
            </Menu>
        </StyledWrapper>
    )
}

export default JobSearch;