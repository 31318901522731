import { TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

const StyledTextField = styled(TextField)(({ theme }) => ({
backgroundColor: "#FFFFFF",
  "& .MuiInputBase-input::placeholder": {
    fontSize: "14px",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    "&:hover fieldset": {
      borderColor: "#007AFF",
    },
  },
}));

export const FormikStyledTextField = ({
  field,
  form: { touched, errors },
  ...props
}: any) => {
  const errorText = touched[field.name] && errors[field.name];

  return (
    <StyledTextField
      {...field}
      {...props}
      error={!!errorText}
      helperText={errorText}
    />
  );
};

export const Label = styled(Typography)({
  fontSize: "14px",
  fontWeight: "bold",
  marginBottom: "6px",
  color: "#333",
});

export const ErrorText = styled(Typography)({
  fontSize: "12px",
  color: "red",
  marginTop: "4px",
});
