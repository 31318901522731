import React, { useState, useRef, useEffect } from "react";
import { useMenuVisibility } from "../../providers/menuVisibilityProvider";
import OuterDiv from "../../components/Common/OuterDivComponent";
import EvalutionType from "./SourcingType";
import JobDetails from "./JobDetails";
import AddJobHeader from "./Components/AddJobHeader";
import AIChat from "./AI-Chat";
import ReviewJobBar from "./ReviewJobBar";
import Thankyou from "./Thankyou";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useMessagePopup } from "context/messagePopContext";
import { OverlayLoader } from "components/Common/Loader/loaderWithOverlay";

const AddJob: React.FC = () => {
  //"createJob" | "jobDetails" | "aiChat" | "reviewJobBar" | "thankyou"
  const [activeStep, setActiveStep] = useState<string>("createJob");
  const [sourcingType, setSourcingType] = useState<string>("");
  const createJobSubmitBtnRef = useRef<any>();
  const jobDetailsSubmitBtnRef = useRef<any>();
  const AIChatSubmitBtnRef = useRef<any>();
  const ReviewJobBarSubmitBtnRef = useRef<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const history = useHistory();
  const message = useMessagePopup();

  const params = new URLSearchParams(window.location.search);
  const jId = params.get("jobId") || "";
  const [jobId] = useState(jId);
  const isEdit = Boolean(jId) && window.location.pathname.includes("edit-job");

  const handleSetJobId = (jobId: string, step: string) => {
    const newSearchParams = new URLSearchParams(window.location.search);
    if (
      step === "jobDetails" &&
      (jobId === "EXPERT_EVAL" || jobId === "SOURCE_TALENT")
    ) {
      setSourcingType(jobId);
    } else {
      newSearchParams.set("jobId", jobId);
    }
    
    newSearchParams.set("step", step);

    history.push({
      pathname: window.location.pathname,
      search: newSearchParams.toString(),
    });

    setActiveStep(step);
  };

  const handleNext = () => {
    switch (activeStep) {
      case "createJob":
        createJobSubmitBtnRef.current.click();
        break;
      case "jobDetails":
        jobDetailsSubmitBtnRef.current.click();
        break;
      case "aiChat":
        AIChatSubmitBtnRef.current.click();
        break;
      case "reviewJobBar":
        ReviewJobBarSubmitBtnRef.current.click();
        break;
      default:
        alert("Invalid step");
        break;
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const step = params.get("step") || "";
    if (isEdit && !step) {
      setActiveStep("jobDetails");
    } else {
      if (!step){
        setActiveStep("createJob");
      } else {
        setActiveStep(step);
      }
      
    }
  }, [jobId, location.search]);

  const handleCancel = () => {
    message.confirm("Are you sure to cancel?", () =>
      history.push("/job-search")
    );
  };

  const { isMenuVisible } = useMenuVisibility()!;

  return (
    <OuterDiv
      {...{ isMaximizeContent: !isMenuVisible }}
      style={{ background: "#f2f7fa" }}
    >
      <AddJobHeader
        onNext={handleNext}
        onCancel={handleCancel}
        disableNext={false}
        step={activeStep}
        evaluationMethod={sourcingType}
      />
      <OverlayLoader loading={loading} />

      {(activeStep === "createJob" && (
        <EvalutionType
          setLoading={setLoading}
          handleSetJobId={handleSetJobId}
          submitBtnRef={jobDetailsSubmitBtnRef}
        />
      )) ||
        (activeStep === "jobDetails" && (
          <JobDetails
            setLoading={setLoading}
            handleSetJobId={handleSetJobId}
            sourcingType={sourcingType}
            submitBtnRef={jobDetailsSubmitBtnRef}
          />
        )) ||
        (activeStep === "aiChat" && (
          <AIChat
            setLoading={setLoading}
            handleSetJobId={handleSetJobId}
            submitBtnRef={AIChatSubmitBtnRef}
          />
        )) ||
        (activeStep === "reviewJobBar" && (
          <ReviewJobBar
            setLoading={setLoading}
            handleSetJobId={handleSetJobId}
            submitBtnRef={ReviewJobBarSubmitBtnRef}
          />
        )) ||
        (activeStep === "thankyou" && <Thankyou />)}
    </OuterDiv>
  );
};

export default AddJob;
