import React from "react";
import { Box, Button, Typography, Link, Breadcrumbs } from "@mui/material";
import { styled } from "@mui/material/styles";

interface AddJobHeaderProps {
  onNext: () => void;
  onCancel: () => void;
  disableNext: boolean;
  step: string;
  evaluationMethod: string;
}

const HeaderContainer = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "12px 24px",
  background: "#FFFFFF",
  borderBottom: "1px solid #D9DDE3",
  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
});

const StyledBreadcrumbs = styled(Breadcrumbs)({
  fontSize: "14px",
  fontWeight: "500",
});

const StyledButton = styled(Button)({
  fontSize: "14px",
  fontWeight: "bold",
});

const AddJobHeader: React.FC<AddJobHeaderProps> = ({
  onNext,
  onCancel,
  disableNext,
  step,
  evaluationMethod
}) => {

  if (step === "thankyou"){
    return <></>
  }
  return (
    <HeaderContainer>
      <StyledBreadcrumbs separator="›" aria-label="breadcrumb">
        <Typography
          color={step === "createJob" ? "#000" : "inherit"}
          fontWeight={step === "createJob" ? "bold" : "normal"}
        >
          Create Job
        </Typography>

        {step !== "createJob" && (
          <Typography
            color={step === "jobDetails" ? "#000" : "inherit"}
            fontWeight={step === "jobDetails" ? "bold" : "normal"}
          >
            Job Details
          </Typography>
        )}

        {step === "aiChat" && (
          <Typography color="#000" fontWeight="bold">
            AI Chat
          </Typography>
        )}

        {step === "reviewJobBar" && (
          <Typography color="#000" fontWeight="bold">
            Review Job Bar
          </Typography>
        )}
      </StyledBreadcrumbs>

      <Box>
        {step !== "createJob" && (
          <StyledButton variant="text" sx={{ mr: 2, color: "#667085" }}>
            Save Draft
          </StyledButton>
        )}
        
        <StyledButton
          variant="outlined"
          sx={{ mr: 2, borderColor: "#1976d2", color: "#1976d2" }}
          onClick={onCancel}
        >
          Cancel
        </StyledButton>
        {evaluationMethod === "ias" && <StyledButton
          sx={{
            px: "20px",
            py: "6px",
            background: "#1976d2",
            color: "#fff",
            "&:hover": { background: "#1565c0" },
          }}
          variant="contained"
          onClick={onNext}
          disabled={disableNext}
        >
          {step === "createJob"
            ? "Next"
            : step === "jobDetails"
            ? "Publish" : "" }
        </StyledButton> || <StyledButton
          sx={{
            px: "20px",
            py: "6px",
            background: "#1976d2",
            color: "#fff",
            "&:hover": { background: "#1565c0" },
          }}
          variant="contained"
          onClick={onNext}
          disabled={disableNext}
        >
          {step === "createJob"
            ? "Next"
            : step === "jobDetails"
            ? "Continue"
            : step === "aiChat"
            ? "Review Job Bar"
            : step === "reviewJobBar"
            ? "Publish Job"
            : ""}
        </StyledButton>}
      </Box>
    </HeaderContainer>
  );
};

export default AddJobHeader;
