import {
    changeCandidateStatusAction,
    createCandidateAction,
    createJobAction,
    getAllTracksForJobsAction,
    getJobCandidatesAction,
    getJobDeskAction,
    getJobDeskConfigurationAction,
    getJobNotificationsAction,
    getJobsAction,
    getTrackTreeAction,
    saveJobDeskConfigurationAction,
    sendReminderToCandidateAction,
    updateCompanyAction,
    updateEmployeeDetailsAction,
    updateJobAction,
} from 'actions/hiringManager/jobs/jobsActions';
import { updateCandidateInfo } from 'actions/hiringManager/jobs/jobsSlice';
import { Country, ICity, ICountry, IState, State } from 'country-state-city';
import { useDispatch, useSelector } from 'react-redux';
import {
    AttributeEntity,
    ChangeCandidateStatusRequest,
    CreateJobRequest,
    GetJobDeskConfigurationRequest,
    ICityExpert,
    ICountryExpert,
    IStateExpert,
    JobNotificationsRequest,
    JobSearchRequest,
    LocationType,
    OptionsDataType,
    PaginationRequest,
    STATUS_TYPE,
    SaveJobDeskConfigurationRequest,
    UpdateB2BEntityDetails,
    UpdateJobRequest
} from 'types/Jobs';
import { getFolderPathAfterDomainName } from 'utilities/commonUtils';
import { RootState } from '../../store';

const useJobDispatcher = () => {
    const auth = useSelector((state: RootState) => state.auth);
    const { jobResults, jobFilter } = useSelector((state: RootState) => state.jobs);
    const { expertId, companyName: companyProvide } = auth.user;
    const dispatch = useDispatch();

    const updateCandidateInfoDispatcher = (candidateState: STATUS_TYPE, candidateId: string, values: any) => {
        dispatch(updateCandidateInfo({ candidateState, candidateIdToUpdate: candidateId, updatedValues: values }));
    }

    const findJobFromJobId = (jobId: string) => {
        return jobResults?.find(job => job.jobId === jobId)
    }

    const getAllTracksForJob = () => {
        dispatch(getAllTracksForJobsAction({ expertId }));
    };

    const getTrackTree = (trackId: string) => {
        dispatch(getTrackTreeAction({ trackId, expertId }));
    };

    const getJobs = (request?: JobSearchRequest) => {
        const req = request ? { ...jobFilter, ...request } : { expertId, ...jobFilter };
        req.publicJobs = req.publicJobs ? true : undefined;
        dispatch(getJobsAction(req));
    };

    const getJobCandidates = ({ jobId }: { jobId: string }) => {
        dispatch(getJobCandidatesAction({ expertId, jobId }));
    };

    const createJob = (request: any, selectedSubCategoriesVals?: any) => {
        const { checkedKeys, trackTree, ...formValues } = request;
        const attributes = getAttributesForCreateJob(checkedKeys, trackTree, selectedSubCategoriesVals);

        const createJobRequest: CreateJobRequest = {
            ...formValues, expertId, companyProvide, attributes
        };

        dispatch(createJobAction(createJobRequest));
    };

    const updateJob = (request: Omit<UpdateJobRequest, 'expertId'>) => {
        dispatch(updateJobAction({ ...request, expertId }));
    };

    const createCandidate = ({ email, jobId, fullname, fastTrack, meetingSchedule, sendEmail }: { email: string, jobId: string, fullname: string, fastTrack: boolean, meetingSchedule: string, resumeUrl?: string, sendEmail: boolean }) => {
        const request = { email, fullname, jobId, expertId, fastTrack, meetingSchedule, sendEmail };
        dispatch(createCandidateAction(request));
    };

    const changeCandidateStatus = (request: Omit<ChangeCandidateStatusRequest, 'expertId'>) => {
        dispatch(changeCandidateStatusAction({ ...request, expertId }));
    };

    const sendReminderToCandidate = (request: Omit<ChangeCandidateStatusRequest, 'expertId' | 'fastTrack' | 'reason'>) => {
        dispatch(sendReminderToCandidateAction({ ...request, expertId }));
    };

    const updateEmployeeDetails = (request: Omit<UpdateB2BEntityDetails, 'expertId'>) => {
        const payloadRequest = {
            ...request,
            expertId,
            photoURL: getFolderPathAfterDomainName(request['photoURL'] ?? ""),
            companyLogo: getFolderPathAfterDomainName(request['companyLogo'] ?? "")
        }
        dispatch(updateEmployeeDetailsAction(payloadRequest));
    };

    const updateCompany = (request: Omit<UpdateB2BEntityDetails, 'expertId'>) => {
        const payloadRequest = {
            ...request,
            expertId,
            photoURL: getFolderPathAfterDomainName(request['photoURL'] ?? ""),
            companyLogo: getFolderPathAfterDomainName(request['companyLogo'] ?? "")
        }
        dispatch(updateCompanyAction(payloadRequest));
    }

    const getJobDesk = (request: Omit<PaginationRequest, 'expertId'>) => {
        dispatch(getJobDeskAction({ ...request, expertId }));
    };

    const getJobNotifications = (request: Omit<JobNotificationsRequest, 'expertId'>) => {
        dispatch(getJobNotificationsAction({ ...request, expertId }));
    };

    const saveJobDeskConfiguration = (request: Omit<SaveJobDeskConfigurationRequest, 'expertId'>) => {
        dispatch(saveJobDeskConfigurationAction({ ...request, expertId }));
    };

    const getJobDeskConfiguration = (request: Omit<GetJobDeskConfigurationRequest, 'expertId'>) => {
        dispatch(getJobDeskConfigurationAction({ ...request, expertId }));
    };

    return {
        getAllTracksForJob,
        getTrackTree,
        createJob,
        updateJob,
        getJobs,
        getJobCandidates,
        createCandidate,
        changeCandidateStatus,
        updateEmployeeDetails,
        updateCompany,
        getJobDesk,
        getJobNotifications,
        saveJobDeskConfiguration,
        getJobDeskConfiguration,
        findJobFromJobId,
        sendReminderToCandidate,
        updateCandidateInfoDispatcher
    };
}

export default useJobDispatcher;

export const convertLocationToOptionDataType = (countries: ICountryExpert[], states: IStateExpert[], cities: ICityExpert[]) => {
    const countryOp: OptionsDataType[] = countries.map(country => ({
        element: country.name,
        elementValue: country.isoCode,
        id: country.id
    }));
    const stateOp: OptionsDataType[] = states.map(state => {
        const country = Country.getCountryByCode(state.countryCode);
        return {
            element: `${country?.name}, ${state.name}`,
            elementValue: state.isoCode,
            parent: state.countryCode,
            id: state.id
        }
    });
    const citiesOp: OptionsDataType[] = cities.map(city => {
        const country = Country.getCountryByCode(city.countryCode);
        const state = State.getStateByCodeAndCountry(city.stateCode, city.countryCode);
        return {
            element: `${country?.name}, ${state?.name}, ${city.name}`,
            elementValue: city.name,
            groupKey: `${country?.name}-${state?.name}`,
            parent: `${city.countryCode}-${city.stateCode}`,
            id: city.id
        }
    });
    return [...countryOp, ...stateOp, ...citiesOp];
}

export const getLocationInOptionDataFormat = (locations: LocationType[] | undefined | any[]) => {
    const countries: ICountry[] = [];
    const states: IState[] = [];
    const cities: ICity[] = [];
    for (const location of (locations ?? [])) {
        if (!location.state) {
            countries.push(Country.getCountryByCode(location.countryId)!);
        } else if (!location.state.city) {
            const state = location.state;
            states.push(State.getStateByCodeAndCountry(state.stateId, location.countryId)!);
        } else {
            const state = location.state;
            const city = location.state.city;
            cities.push({
                name: city.cityId,
                countryCode: location.countryId,
                stateCode: state.stateId,
            })
        }
    }
    return convertLocationToOptionDataType(countries, states, cities);
}


export const getFormatLocationForSave = (selectedOptions: OptionsDataType[]) => {
    const selectedLocations: LocationType[] = [];
    const addedIds = new Set<string>();
    for (const selectedOption of selectedOptions) {
        if (!selectedOption.parent) {
            const countryId = selectedOption.elementValue;
            if (!addedIds.has(countryId)) {
                selectedLocations.push({
                    countryName: Country.getCountryByCode(countryId)?.name!,
                    countryId: countryId,
                })
            }
        } else if (selectedOption.parent.split('-').length === 1) {
            const countryId = selectedOption.parent;
            const stateId = selectedOption.elementValue;
            const key = countryId + "-" + stateId;
            if (!addedIds.has(key)) {
                selectedLocations.push({
                    countryName: Country.getCountryByCode(countryId)?.name!,
                    countryId: countryId,
                    state: {
                        stateName: State.getStateByCodeAndCountry(stateId, countryId)?.name!,
                        stateId: stateId
                    }
                })
                addedIds.add(key);
            }
        } else if (selectedOption.parent.split('-').length === 2) {
            const [countryId, stateId] = selectedOption.parent?.split('-');
            const key = countryId + "-" + stateId + "-" + selectedOption.elementValue;
            if (!addedIds.has(key)) {
                selectedLocations.push({
                    countryName: Country.getCountryByCode(countryId)?.name!,
                    countryId: countryId,
                    state: {
                        stateName: State.getStateByCodeAndCountry(stateId, countryId)?.name!,
                        stateId: stateId,
                        city: {
                            cityName: selectedOption.element,
                            cityId: selectedOption.elementValue,
                        }
                    }
                })
                addedIds.add(key);
            }
        }
    }
    return selectedLocations;
}


export const jobTypes = [
    { key: 'REMOTE', value: 'Remote' },
    { key: 'HYBRID', value: 'Hybrid' },
    { key: 'ONSITE', value: 'Onsite' }
];

export const employmentTypes = [
    { key: 'FULL_TIME', value: 'Full Time' },
    { key: 'CONTRACT', value: 'Contract' },
    { key: 'PART_TIME', value: 'Part Time' },
];

export const DEFAULT_EMPLOYMENT_TYPE = 'FULL_TIME';

export const getAttributesForCreateJob = (checkedKeys: any, trackTree: any, selectedSubCategoriesVals?: any) => {
    const categories: string[] = [];
    const attributes: AttributeEntity[] = [];

    checkedKeys.forEach((key: any) => {
        const [categoryId, categoryType, subCategoryId, subCategoryType] = key.split('-');
        const category = trackTree.find((tree: any) => tree.categoryId === categoryId);
        if (subCategoryId && category) {
            const { categoryName } = category;
            if (!categories.includes(categoryId)) {
                const entityObj = {
                    entity: categoryType,
                    entityTitle: categoryName,
                    entityId: categoryId,
                    score: selectedSubCategoriesVals[categoryId + '-' + categoryType]?.value,
                    children: [],
                };
                categories.push(categoryId);
                attributes.push(entityObj);
            }
            const entityObj = attributes.find(o => o.entityId === categoryId);
            const subCategoryObj = {
                entity: subCategoryType,
                entityTitle: category?.subCategories?.find((subCat: any) => subCat.subCategoryId === subCategoryId)?.subCategoryName ?? "",
                entityId: subCategoryId,
                score: selectedSubCategoriesVals[key]?.value,
            };
            entityObj?.children?.push(subCategoryObj);
        }
    });

    return attributes;
};

export const JOB_ACCESS_DEFAULT = "PRIVATE";

export const JOB_ACCESS_CONSTANT = {
    "PRIVATE": "PRIVATE", "PUBLIC": "PUBLIC"
}

export const JOB_ACCESS_LIST = [
    { text: "Private", value: JOB_ACCESS_CONSTANT.PRIVATE },
    { text: "Public", value: JOB_ACCESS_CONSTANT.PUBLIC },
]

export const DEFAULT_CURRENCY = 'USD';

export const currencyList = [
    { code: "AFN", text: "Afghanistan Afghanis - AFN", icon: "؋" },
    { code: "ALL", text: "Albania Leke - ALL", icon: "Lek" },
    { code: "DZD", text: "Algeria Dinars - DZD", icon: "دج" },
    { code: "ARS", text: "Argentina Pesos - ARS", icon: "$" },
    { code: "AUD", text: "Australia Dollars - AUD", icon: "$" },
    { code: "ATS", text: "Austria Schillings - ATS", icon: "S" },
    { code: "BSD", text: "Bahamas Dollars - BSD", icon: "$" },
    { code: "BHD", text: "Bahrain Dinars - BHD", icon: ".د.ب" },
    { code: "BDT", text: "Bangladesh Taka - BDT", icon: "৳" },
    { code: "BBD", text: "Barbados Dollars - BBD", icon: "$" },
    { code: "BEF", text: "Belgium Francs - BEF", icon: "FB" },
    { code: "BMD", text: "Bermuda Dollars - BMD", icon: "$" },
    { code: "BRL", text: "Brazil Reais - BRL", icon: "R$" },
    { code: "BGN", text: "Bulgaria Leva - BGN", icon: "лв" },
    { code: "CAD", text: "Canada Dollars - CAD", icon: "$" },
    { code: "XOF", text: "CFA BCEAO Francs - XOF", icon: "CFA" },
    { code: "XAF", text: "CFA BEAC Francs - XAF", icon: "CFA" },
    { code: "CLP", text: "Chile Pesos - CLP", icon: "$" },
    { code: "CNY", text: "China Yuan Renminbi - CNY", icon: "¥" },
    { code: "COP", text: "Colombia Pesos - COP", icon: "$" },
    { code: "XPF", text: "CFP Francs - XPF", icon: "₣" },
    { code: "CRC", text: "Costa Rica Colones - CRC", icon: "₡" },
    { code: "HRK", text: "Croatia Kuna - HRK", icon: "kn" },
    { code: "CYP", text: "Cyprus Pounds - CYP", icon: "£" },
    { code: "CZK", text: "Czech Republic Koruny - CZK", icon: "Kč" },
    { code: "DKK", text: "Denmark Kroner - DKK", icon: "kr" },
    { code: "DEM", text: "Deutsche (Germany) Marks - DEM", icon: "DM" },
    { code: "DOP", text: "Dominican Republic Pesos - DOP", icon: "RD$" },
    { code: "NLG", text: "Dutch (Netherlands) Guilders - NLG", icon: "ƒ" },
    { code: "XCD", text: "Eastern Caribbean Dollars - XCD", icon: "$" },
    { code: "EGP", text: "Egypt Pounds - EGP", icon: "£" },
    { code: "EEK", text: "Estonia Krooni - EEK", icon: "KR" },
    { code: "EUR", text: "Euro - EUR", icon: "€" },
    { code: "FJD", text: "Fiji Dollars - FJD", icon: "$" },
    { code: "FIM", text: "Finland Markkaa - FIM", icon: "mk" },
    { code: "FRF", text: "France Francs - FRF", icon: "₣" },
    { code: "XAU", text: "Gold Ounces - XAU", icon: "Au" },
    { code: "GRD", text: "Greece Drachmae - GRD", icon: "₯" },
    { code: "GTQ", text: "Guatemalan Quetzal - GTQ", icon: "Q" },
    { code: "HKD", text: "Hong Kong Dollars - HKD", icon: "$" },
    { code: "HUF", text: "Hungary Forint - HUF", icon: "Ft" },
    { code: "ISK", text: "Iceland Kronur - ISK", icon: "kr" },
    { code: "XDR", text: "IMF Special Drawing Right - XDR", icon: "SDR" },
    { code: "INR", text: "India Rupees - INR", icon: "₹" },
    { code: "IDR", text: "Indonesia Rupiahs - IDR", icon: "Rp" },
    { code: "IRR", text: "Iran Rials - IRR", icon: "﷼" },
    { code: "IQD", text: "Iraq Dinars - IQD", icon: "ع.د" },
    { code: "IEP", text: "Ireland Pounds - IEP", icon: "£" },
    { code: "ILS", text: "Israel New Shekels - ILS", icon: "₪" },
    { code: "ITL", text: "Italy Lire - ITL", icon: "₤" },
    { code: "JMD", text: "Jamaica Dollars - JMD", icon: "$" },
    { code: "JPY", text: "Japan Yen - JPY", icon: "¥" },
    { code: "JOD", text: "Jordan Dinars - JOD", icon: "د.ا" },
    { code: "KES", text: "Kenya Shillings - KES", icon: "KSh" },
    { code: "KRW", text: "Korea (South) Won - KRW", icon: "₩" },
    { code: "KWD", text: "Kuwait Dinars - KWD", icon: "د.ك" },
    { code: "LBP", text: "Lebanon Pounds - LBP", icon: "ل.ل" },
    { code: "LUF", text: "Luxembourg Francs - LUF", icon: "₣" },
    { code: "MYR", text: "Malaysia Ringgits - MYR", icon: "RM" },
    { code: "MTL", text: "Malta Liri - MTL", icon: "₤" },
    { code: "MUR", text: "Mauritius Rupees - MUR", icon: "₨" },
    { code: "MXN", text: "Mexico Pesos - MXN", icon: "$" },
    { code: "MAD", text: "Morocco Dirhams - MAD", icon: "د.م." },
    { code: "NZD", text: "New Zealand Dollars - NZD", icon: "$" },
    { code: "NOK", text: "Norway Kroner - NOK", icon: "kr" },
    { code: "OMR", text: "Oman Rials - OMR", icon: "ر.ع." },
    { code: "PKR", text: "Pakistan Rupees - PKR", icon: "₨" },
    { code: "XPD", text: "Palladium Ounces - XPD", icon: "Pd" },
    { code: "PEN", text: "Peru Nuevos Soles - PEN", icon: "S/" },
    { code: "PHP", text: "Philippines Pesos - PHP", icon: "₱" },
    { code: "XPT", text: "Platinum Ounces - XPT", icon: "Pt" },
    { code: "PLN", text: "Poland Zlotych - PLN", icon: "zł" },
    { code: "PTE", text: "Portugal Escudos - PTE", icon: "₯" },
    { code: "QAR", text: "Qatar Riyals - QAR", icon: "ر.ق" },
    { code: "RON", text: "Romania New Lei - RON", icon: "lei" },
    { code: "ROL", text: "Romania Lei - ROL", icon: "lei" },
    { code: "RUB", text: "Russia Rubles - RUB", icon: "₽" },
    { code: "SAR", text: "Saudi Arabia Riyals - SAR", icon: "﷼" },
    { code: "XAG", text: "Silver Ounces - XAG", icon: "Ag" },
    { code: "SGD", text: "Singapore Dollars - SGD", icon: "$" },
    { code: "SKK", text: "Slovakia Koruny - SKK", icon: "Sk" },
    { code: "SIT", text: "Slovenia Tolars - SIT", icon: "SIT" },
    { code: "ZAR", text: "South Africa Rand - ZAR", icon: "R" },
    { code: "ESP", text: "Spain Pesetas - ESP", icon: "₧" },
    { code: "LKR", text: "Sri Lanka Rupees - LKR", icon: "₨" },
    { code: "SDD", text: "Sudan Dinars - SDD", icon: "£" },
    { code: "SEK", text: "Sweden Kronor - SEK", icon: "kr" },
    { code: "CHF", text: "Switzerland Francs - CHF", icon: "CHF" },
    { code: "TWD", text: "Taiwan New Dollars - TWD", icon: "NT$" },
    { code: "THB", text: "Thailand Baht - THB", icon: "฿" },
    { code: "TTD", text: "Trinidad and Tobago Dollars - TTD", icon: "$" },
    { code: "TND", text: "Tunisia Dinars - TND", icon: "د.ت" },
    { code: "TRY", text: "Turkey New Lira - TRY", icon: "₺" },
    { code: "AED", text: "United Arab Emirates Dirhams - AED", icon: "د.إ" },
    { code: "GBP", text: "United Kingdom Pounds - GBP", icon: "£" },
    { code: "USD", text: "United States Dollars - USD", icon: "$" },
    { code: "VEB", text: "Venezuela Bolivares - VEB", icon: "Bs" },
    { code: "VND", text: "Vietnam Dong - VND", icon: "₫" },
    { code: "ZMK", text: "Zambia Kwacha - ZMK", icon: "ZK" },
];