import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

import { fetchCampaignAndAnalytics } from "actions/campaign/campaignActions";
import { getJobDetails } from "./services";
import { OverlayLoader } from "components/Common/Loader/loaderWithOverlay";

import { BASE_API_URL, DEFAULT_TOKEN } from "utilities/constants";
import { RootState } from "store";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const Thankyou: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const expertId = useSelector((state: RootState) => state.auth.user?.expertId);

  const [loading, setLoading] = useState(false);
  const [campaignCreating, setCampaignCreating] = useState(false);
  const [campaignCreated, setCampaignCreated] = useState(false);
  const [status, setStatus] = useState<string | null>(null);
  const [jobLink, setJobLink] = useState<string>("");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const shareMenuOpen = Boolean(anchorEl);

  const jobId = new URLSearchParams(window.location.search).get("jobId") || "";

  useEffect(() => {
    if (!jobId || !expertId) return;

    const fetchJobDetails = async () => {
      setLoading(true);
      try {
        const data = await getJobDetails({ jobId, expertId });
        if (data.apiStatus === "SUCCESS") {
          const { campaigns = [], jobLink } =
            data.output?.campaignDetails || {};
          setCampaignCreated(campaigns?.length > 0);
          setJobLink(data.output?.jobLink || "");
        }
      } catch (error) {
        toast.error("Failed to fetch job details");
      } finally {
        setLoading(false);
      }
    };

    fetchJobDetails();
  }, [jobId, expertId]);

  const createCampaign = async () => {
    if (!jobId || !expertId) return;

    setCampaignCreating(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${DEFAULT_TOKEN}`,
    };

    try {
      const res = await fetch(
        `${BASE_API_URL}/evaluationPlatform/expert/startJobCampaign`,
        {
          method: "POST",
          headers,
          body: JSON.stringify({ jobId, expertId, token: DEFAULT_TOKEN }),
        }
      );

      const data = await res.json();

      if (!res.ok) {
        toast.error(data.apiMessage || "Failed to create campaign");
        throw new Error(data.message || "Unknown error");
      }

      toast.success(data.apiMessage);
      pollCampaignProgress();
    } catch (error) {
      console.error("Campaign creation error:", error);
    } finally {
      setCampaignCreating(false);
    }
  };

  const pollCampaignProgress = () => {
    setStatus("STARTED");

    const intervalId = setInterval(async () => {
      try {
        const res = await fetch(
          `${BASE_API_URL}/evaluationPlatform/expert/campaign/getSetupProgress`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${DEFAULT_TOKEN}`,
            },
            body: JSON.stringify({ jobId, expertId, token: DEFAULT_TOKEN }),
          }
        );

        const data = await res.json();
        const campaignStatus = data.output?.status;

        if (campaignStatus === "COMPLETED") {
          clearInterval(intervalId);
          setStatus("COMPLETED");
          setCampaignCreated(true);
          toast.success("Campaign created successfully!");
        } else if (campaignStatus === "FAILED") {
          clearInterval(intervalId);
          setStatus("FAILED");
          toast.error(`Campaign creation failed: ${data.output?.message}`);
        }
      } catch {
        clearInterval(intervalId);
        toast.error("Error checking campaign status");
      } finally {
        setCampaignCreating(false);
      }
    }, 3000);
  };

  const handleViewCampaignClick = async () => {
    try {
      const result = await dispatch(
        fetchCampaignAndAnalytics({ jobId, expertId, token: "123" })
      );
      history.push(`/campaign?jobId=${jobId}`, { campaignData: result });
    } catch {
      toast.error("Failed to fetch campaign");
    }
  };

  const handleClick = () => {
    campaignCreated || status === "COMPLETED"
      ? handleViewCampaignClick()
      : createCampaign();
  };

  const renderButtonLabel = () => {
    if (campaignCreating || status === "STARTED") {
      return (
        <>
          <CircularProgress size={20} color="inherit" sx={{ mr: 1 }} />
          Creating Campaign...
        </>
      );
    }
    return campaignCreated || status === "COMPLETED"
      ? "View Campaign"
      : "Create a Campaign";
  };

  const handleShareClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseShareMenu = () => {
    setAnchorEl(null);
  };

  const handleShare = (
    platform: "facebook" | "linkedin" | "twitter" | "copy"
  ) => {
    handleCloseShareMenu();
    const encodedUrl = encodeURIComponent(jobLink);
    const title = encodeURIComponent(document.title);

    switch (platform) {
      case "facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
          "_blank",
          "width=600,height=400"
        );
        break;
      case "linkedin":
        window.open(
          `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`,
          "_blank",
          "width=600,height=400"
        );
        break;
      case "twitter":
        window.open(
          `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${title}`,
          "_blank",
          "width=600,height=400"
        );
        break;
      case "copy":
        navigator.clipboard.writeText(jobLink);
        toast.success("Link copied to clipboard");
        break;
    }
  };
  const shareBtnRef = useRef<HTMLButtonElement>(null);

  return (
    <Box
      sx={{
        marginTop: "5%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <OverlayLoader loading={loading} />
      <Box
        sx={{
          maxWidth: 650,
          width: "100%",
          padding: 5,
          textAlign: "center",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
        
          <img
            src="https://s3-alpha-sig.figma.com/img/f902/892a/ce3811c4db79f4b9fa3fd305f896f90e?Expires=1744588800&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=YdQSKA-Us4FLEAyxH88hXP42~N9XGe4pqfoyvDs-TRbc5RFhOkiZT4vlnCrMRcI0uGGCWWqX-FmZBxQAeSUl4NcpsnSw~TTZ4CE481ZH82psRu0xeVGaLdsidIpKxS-pckHq6qCqCyXQFI0RyjV7ritwJHlk6pwBwAwnglhG2tocTcSVNZmH0~n8L87YSxIHoMcbnbv8dzDb9ihfnTGjk66oOGc5uvL4Hn6lVzAZ8wygdx-dSF6umS3h4IX2~6KsPIt2FM~gTXvm2FIXMZxzP9S6N1EeqgLYvcMy8p9QG4O-jdBRngPTG2XBVWnelUOjqb15jiQoq1fdTXmLNyb9LQ__"
            alt="Success Animation"
            style={{ width: 200, height: 200 }}
          />
        </Box>

        <Typography variant="h5" fontWeight={700} mb={2}>
          Congratulations! Your Job is Published
        </Typography>

        <Typography variant="body1" color="text.secondary" mb={4}>
          Your Product Design Job is ready to be shared with candidates and
          start a bias-free evaluation. You can also launch a campaign to
          generate matching leads.
        </Typography>

        <Box sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
          <Button
            ref={shareBtnRef}
            variant="outlined"
            onClick={handleShareClick}
            endIcon={<ShareIcon />}
            sx={{
              borderColor: "#1976d2",
              color: "#1976d2",
              textTransform: "none",
              fontWeight: 600,
              fontSize: "14px",
              px: 4,
              py: 1.5,
            }}
          >
            Share & Invite Talent
          </Button>

          <Menu
            anchorEl={anchorEl}
            open={shareMenuOpen}
            onClose={handleCloseShareMenu}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            PaperProps={{
              sx: {
                width: shareBtnRef.current?.offsetWidth || 200,
                border: "1px solid #1976d2",
                backgroundColor: "#F2F7FA",
                boxShadow: 2,
                mt: 1,
                borderRadius: 1,
              },
            }}
          >
            <MenuItem
              onClick={() => handleShare("facebook")}
              sx={{ color: "#1976d2" }}
            >
              <FacebookIcon fontSize="small" sx={{ mr: 1 }} />
              Share on Facebook
            </MenuItem>
            <MenuItem
              onClick={() => handleShare("linkedin")}
              sx={{ color: "#1976d2" }}
            >
              <LinkedInIcon fontSize="small" sx={{ mr: 1 }} />
              Share on LinkedIn
            </MenuItem>
            <MenuItem
              onClick={() => handleShare("twitter")}
              sx={{ color: "#1976d2" }}
            >
              <TwitterIcon fontSize="small" sx={{ mr: 1 }} />
              Share on Twitter
            </MenuItem>
            <MenuItem
              onClick={() => handleShare("copy")}
              sx={{ color: "#1976d2" }}
            >
              <ContentCopyIcon fontSize="small" sx={{ mr: 1 }} />
              Copy Link
            </MenuItem>
          </Menu>

          <Button
            variant="contained"
            disabled={campaignCreating || status === "STARTED"}
            onClick={handleClick}
            sx={{
              color: "#fff",
              textTransform: "none",
              fontWeight: 600,
              fontSize: "14px",
              px: 4,
              py: 1.5,
              minWidth: "180px",
            }}
          >
            {renderButtonLabel()}
          </Button>
        </Box>

        <Button
          onClick={() => history.push("/job-search")}
          sx={{
            color: "blue",
            textTransform: "none",
            fontSize: "14px",
            marginTop: "40px",
            textDecoration: "underline",
          }}
        >
          &#8592; Back to Job Listing
        </Button>
      </Box>
    </Box>
  );
};

export default Thankyou;
