import React, { useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Chip,
  IconButton,
  Slider,
  Select,
  TextField,
  MenuItem,
  Button,
  FormControl,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

interface JobCategoryCardProps {
  category: {
    name: string;
    values: { name: string; difficultyLevel: string }[];
    requiredWeight: number;
    insights: {
      insight: string;
      potentialRisk: string;
      suggestions: string[];
    };
  };
  showWeightBox: boolean;
  showChatBox: boolean;
  updateCategory: (
    name: string,
    newWeight: number,
    newValues: { name: string; difficultyLevel: string }[]
  ) => void;
}

const JobCategoryCard: React.FC<JobCategoryCardProps> = ({
  category,
  updateCategory,
  showChatBox,
  showWeightBox,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [weight, setWeight] = useState(category.requiredWeight);
  const [skills, setSkills] = useState(
    category.values.map((v) => ({ skill: v.name, level: v.difficultyLevel }))
  );

  const handleExpandClick = () => setExpanded(!expanded);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    const updatedWeight = Array.isArray(newValue) ? newValue[0] : newValue;
    setWeight(updatedWeight);
    updateCategory(category.name, updatedWeight, skills.map(s => ({
      name: s.skill,
      difficultyLevel: s.level,
    })));
  };

  const handleSkillChange = (index: number, field: "skill" | "level", value: string) => {
    const updatedSkills = skills.map((s, i) =>
      i === index ? { ...s, [field]: value } : s
    );
    setSkills(updatedSkills);
    updateCategory(category.name, weight, updatedSkills.map(s => ({
      name: s.skill,
      difficultyLevel: s.level,
    })));
  };

  const handleAddSkill = () => {
    const updated = [...skills, { skill: "", level: "" }];
    setSkills(updated);
    updateCategory(category.name, weight, updated.map(s => ({
      name: s.skill,
      difficultyLevel: s.level,
    })));
  };

  return (
    <Paper sx={{ mb: 2, p: 2, border: "1px solid #D9DDE3", borderRadius: "8px" }}>
      <Box sx={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
        <Box sx={{ width: showWeightBox ? "40%" : "15%" }}>
          <Typography sx={{ fontWeight: 600, mb: 1, fontSize: "13px" }}>
            {category.name} required
          </Typography>
          <Typography fontSize="12px" color="#656E81">
            Must-have criteria for this role.
          </Typography>

          {showWeightBox && (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 1 }}>
              {category.values.map((skill) => (
                <Chip
                  key={skill.name}
                  label={skill.name}
                  sx={{
                    backgroundColor: "#5051F91A",
                    color: "#344054",
                    borderRadius: "6px",
                    fontSize: "12px",
                  }}
                />
              ))}
            </Box>
          )}
        </Box>

        {!showWeightBox && (
          <Box
            sx={{
              width: "25%",
              borderLeft: 1,
              borderRight: 1,
              borderColor: "#f0f0f0",
              px: 3,
            }}
          >
            <Typography sx={{ mb: 2, color: "#000", fontSize: "12px" }}>Criteria</Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {category.values.map((skill) => (
                <Chip
                  key={skill.name}
                  label={skill.name}
                  sx={{
                    backgroundColor: "#5051F91A",
                    color: "#344054",
                    borderRadius: "6px",
                    fontSize: "12px",
                  }}
                />
              ))}
            </Box>
          </Box>
        )}

        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, width: "55%" }}>
          <Typography sx={{ fontWeight: 500, fontSize: "12px", mb: 0.5 }}>
            Criteria Weight
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Box
              sx={{
                width: "120px",
                display: "flex",
                alignItems: "center",
                height: "8px",
                backgroundColor: "#E5E7EB",
                borderRadius: "4px",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  width: `${weight}%`,
                  height: "100%",
                  backgroundColor: "#4F46E5",
                  borderRadius: "4px",
                  transition: "width 0.3s ease-in-out",
                }}
              />
            </Box>
            <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>{weight}%</Typography>
          </Box>
        </Box>

        <IconButton onClick={handleExpandClick} sx={{ p: 1 }}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>

      {expanded && (
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: showChatBox || showWeightBox ? "column" : "row",
            gap: 2,
            bgcolor: "#325CD61A",
            p: 2,
            borderRadius: "8px",
          }}
        >
          
          <Box sx={{ width: showChatBox || showWeightBox ? "90%" : "50%" }}>
            <Typography sx={{ fontWeight: 600, fontSize: "14px", mb: 2 }}>
              {category.name} Criteria
            </Typography>

            {skills.map((entry, index) => (
              <Box key={index} sx={{ display: "flex", gap: 2, mb: 1 }}>
                <FormControl fullWidth size="small" sx={{ mb: 1 }}>
                  <TextField
                  size="small"
                    value={entry.skill}
                    onChange={(e) => handleSkillChange(index, "skill", e.target.value)}
                    sx={{
                      bgcolor: "white",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#E5E7EB",
                      },
                      borderRadius: "8px",
                    }}
                  />
                </FormControl>

                <FormControl fullWidth size="small" sx={{ mb: 1 }}>
                  <Select
                    value={entry.level}
                    onChange={(e) => handleSkillChange(index, "level", e.target.value)}
                    displayEmpty
                    sx={{
                      bgcolor: "white",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#E5E7EB",
                      },
                    }}
                  >
                    <MenuItem value="" disabled>Select Level</MenuItem>
                    <MenuItem value={"Intermediate"}>Intermediate</MenuItem>
                    <MenuItem value={"Advanced"}>Advanced</MenuItem>
                    <MenuItem value={"Expert"}>Expert</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            ))}

            <Button
              startIcon={<AddCircleOutlineIcon sx={{ fontSize: 18 }} />}
              onClick={handleAddSkill}
              variant="text"
              color="primary"
              sx={{
                mt: 1,
                fontSize: "13px",
                bgcolor: "#ffffff",
                padding: "10px 16px",
                textTransform: "none",
                fontWeight: 500,
                color: "#333333",
              }}
            >
              Add more required skills
            </Button>
          </Box>

          
          <Box
            sx={{
              width: showChatBox || showWeightBox ? "90%" : "45%",
              p: 2,
              border: "1px solid #325CD6",
              borderRadius: "8px",
              bgcolor: "#F9FAFB",
            }}
          >
            <Typography sx={{ fontSize: "14px", fontWeight: 600, mb: 2 }}>
              Criteria Weight
            </Typography>
            <Box sx={{ display: "flex", gap: 1, alignItems: "center", justifyContent: "center", mb: 3 }}>
              <Slider
                value={weight}
                onChange={handleSliderChange}
                step={5}
                min={0}
                max={100}
                sx={{ color: "#4F46E5" }}
              />
              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>{weight}%</Typography>
            </Box>

            <Box sx={{ mb: 2, display: "flex", gap: 1 }}>
              <InfoOutlinedIcon sx={{ color: "#325CD6", fontSize: 20 }} />
              <Box>
                <Typography fontSize="14px" fontWeight={700} color="#051638">Insight:</Typography>
                <Typography fontSize="13px">{category.insights.insight}</Typography>
              </Box>
            </Box>

            <Typography sx={{ fontSize: "13px", fontWeight: 700, mb: 1, color: "#051638" }}>
              🔷 Suggestions:
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {category.insights.suggestions.map((suggestion, index) => (
                <Box key={index} sx={{ display: "flex", gap: 1, alignItems: "flex-start" }}>
                  <CheckCircleOutlineIcon sx={{ color: "#10B981", fontSize: 18 }} />
                  <Typography fontSize="13px">{suggestion}</Typography>
                </Box>
              ))}

              <Box sx={{ display: "flex", gap: 1, alignItems: "flex-start" }}>
                <WarningAmberIcon sx={{ color: "#F59E0B", fontSize: 18 }} />
                <Typography fontSize="13px">{category.insights.potentialRisk}</Typography>
              </Box>
            </Box>

            {weight > 20 && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  mt: 2,
                  color: "#F04438",
                }}
              >
                <ErrorOutlineIcon sx={{ fontSize: 18 }} />
                <Typography fontSize="14px" fontWeight={500}>
                  The weight exceeds the limit. Make it ≤ 20% or adjust other criteria.
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default JobCategoryCard;
