import {
  IAddJob,
  IGetJobDetails,
  MissingFieldsStatus,
  ChatHistoryRequest,
  RecruiterAnswer,
  IJobBar,
  IUpdateJobBarDetails,
  IPublishJob
} from "./types";
import { post } from "utilities";
import {
  API_PREFIX,
  API_PREFIX_CANDIDATE,
  API_URL_PREFIX,
  DEFAULT_TOKEN,
} from "utilities/constants";

export const createJob = (payload: IAddJob) => {
  return post(`${API_URL_PREFIX}/v1/createJob`, {
    ...payload,
    token: DEFAULT_TOKEN,
  });
};

export const updateJob = (jobId: string, trackId: string, payload: IAddJob) => {
  return post(`${API_URL_PREFIX}/v1/updateJob`, {
    ...payload,
    jobId,
    trackId,
    token: DEFAULT_TOKEN,
  });
};

export const getJobDetails = (payload: IGetJobDetails) => {
  return post(`${API_URL_PREFIX}/getJobDetails`, {
    ...payload,
    token: DEFAULT_TOKEN,
  });
};

export const getMissingFieldsStatus = (payload: MissingFieldsStatus) => {
  return post(`${API_URL_PREFIX}/job/chat/getMissingRequirements`, {
    ...payload,
    token: DEFAULT_TOKEN,
  });
};

export const getChatHistory = (payload: ChatHistoryRequest) => {
  return post(`${API_URL_PREFIX}/job/chat/history`, {
    ...payload,
    token: DEFAULT_TOKEN,
  });
};

export const submitRecruiterAnswer = (payload: RecruiterAnswer) => {
  return post(`${API_URL_PREFIX}/job/chat/recruiterAnswer`, {
    ...payload,
    token: DEFAULT_TOKEN,
  });
};

export const GetJobBarDetails = (payload: IJobBar) => {
  return post(`${API_URL_PREFIX}/job/getJobBarDetails`, {
    ...payload,
    token: DEFAULT_TOKEN,
  });
};


export const updateJobBarDetails = (payload: IUpdateJobBarDetails) => {
  return post(`${API_URL_PREFIX}/job/updateJobBarDetails`, {
    ...payload,
    token: DEFAULT_TOKEN,
  });
};

export const PublishJob = (payload: IPublishJob) => {
  return post(`${API_URL_PREFIX}/publishJob`, {
    ...payload,
    token: DEFAULT_TOKEN,
  });
};
