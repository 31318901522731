import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  TextField,
  IconButton,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Chip,
} from "@mui/material";
import {
  Send,
  InsertDriveFile,
  Image,
  DoneAll,
  CheckCircle,
  AutoFixHigh,
} from "@mui/icons-material";
import { SvgIcon } from "@mui/material";
import { styled } from "@mui/system";
import { FileDrop } from "react-file-drop";
import {
  getChatHistory,
  submitRecruiterAnswer,
  getMissingFieldsStatus
} from "./services";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IAddJobComponentsProps } from "./types";
import { useMessagePopup } from 'context/messagePopContext';

interface MissingField {
  completed: any;
  description: string;
}

interface Message {
  id: number;
  text: string;
  sender: "AI" | "User";
  timestamp: string;
  delivered?: boolean;
  attachments?: File[];
  pinned?: boolean;
}

const Container = styled(Box)({
  display: "flex",
  gap: "24px",
  width: "99%",
  margin: "auto",
  overflow: "hidden",
  marginTop: "30px",
});

const SidePanel = styled(Card)({
  flex: "0 0 380px",
  padding: "16px",
  borderRadius: "12px",
  background: "#ffffff",
  boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
  border: "1px solid #82B3E6",
  height:"80vh"
});

const ChatBox = styled(Card)({
  width:"1500px",
  borderRadius: "12px",
  display: "flex",
  flexDirection: "column",
  background: "#fff",
  boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
  overflow: "hidden",
  position: "relative",
  height: "80vh",
  border: "1px solid #82B3E6",
  padding: "10px",
  
});
const MessageList = styled(Box)({
  flexGrow: 1,
  overflowY: "auto",
  padding: "16px",
  display: "flex",
  flexDirection: "column",
  gap: "12px",
});

const ChatInputContainer = styled(Box)({
  position: "sticky",
  bottom: 0,
  display: "flex",
  alignItems: "center",
  gap: 8,
  padding: "16px",
  borderTop: "1px solid #e0e0e0",
  background: "#fff",
  transition: "all 0.3s ease",
});

const DottedCircleIcon = (props: any) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <circle cx="12" cy="3" r="1.5" fill="#D3D8E0" />
    <circle cx="18" cy="5" r="1.5" fill="#D3D8E0" />
    <circle cx="21" cy="12" r="1.5" fill="#D3D8E0" />
    <circle cx="18" cy="19" r="1.5" fill="#D3D8E0" />
    <circle cx="12" cy="21" r="1.5" fill="#D3D8E0" />
    <circle cx="6" cy="19" r="1.5" fill="#D3D8E0" />
    <circle cx="3" cy="12" r="1.5" fill="#D3D8E0" />
    <circle cx="6" cy="5" r="1.5" fill="#D3D8E0" />
  </SvgIcon>
);

const ProDottedCircleIcon = (props: any) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <circle cx="12" cy="3" r="1.5" fill="#286BEF" />
    <circle cx="18" cy="5" r="1.5" fill="#286BEF" />
    <circle cx="21" cy="12" r="1.5" fill="#286BEF" />
    <circle cx="18" cy="19" r="1.5" fill="#286BEF" />
    <circle cx="12" cy="21" r="1.5" fill="#286BEF" />
    <circle cx="6" cy="19" r="1.5" fill="#286BEF" />
    <circle cx="3" cy="12" r="1.5" fill="#286BEF" />
    <circle cx="6" cy="5" r="1.5" fill="#286BEF" />
  </SvgIcon>
);

const AIChat: React.FC<IAddJobComponentsProps> = (props) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const messageEndRef = useRef<HTMLDivElement>(null);
  const [missingFields, setMissingFields] = useState<MissingField[]>([]);
  const expertId = useSelector((state: RootState) => state.auth.user.expertId);
  const message = useMessagePopup();

  const params = new URLSearchParams(window.location.search);
  const jId = params.get("jobId") || "";
  const [jobId] = useState(jId);

  const fetchChatHistory = async () => {
    try {
      const response = await getChatHistory({
        jobId: jobId,
        expertId: expertId,
      });

      const chatHistory =
        response.output.jobRequirementChatDetails.missingRequirementChatHistory;

      const transformedMessages = chatHistory.map(
        (msg: any, index: number) => ({
          id: index + 1,
          text: msg.message,
          sender: msg.generatedBy === "SYSTEM" ? "AI" : "User",
          timestamp: new Date(msg.createdAt).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          delivered: true,
        })
      );

      if (transformedMessages.length > messages.length) {
        setMessages(transformedMessages);
      }
    } catch (error) {
      console.error("Failed to fetch chat history:", error);
    } finally {
      props.setLoading(false);
    }
  };

  const fetchMissingFields = async () => {
    try {
      const response = await getMissingFieldsStatus({
        jobId: jobId,
        expertId: expertId,
      });

      if (response.apiStatus === "SUCCESS") {
        setMissingFields(response.output.jobChatMissingRequirementDetails);
      }
    } catch (error) {
      console.error("Failed to fetch missing fields:", error);
    } finally {
      props.setLoading(false);
    }
  };

  useEffect(() => {
    if (jobId && expertId){
      fetchChatHistory();
      fetchMissingFields();
    }
  }, [jobId, expertId]);

  const handleFileDrop = (files: FileList | null) => {
    setIsDragging(false);
    if (files) {
      setFiles((prev) => [...prev, ...Array.from(files)]);
    }
  };

  const handleSendMessage = async () => {
    if (!inputValue.trim() && files.length === 0) return;

    const messageId = messages.length + 1;

    const userMessage: Message = {
      id: messageId,
      text: inputValue,
      sender: "User",
      timestamp: new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
      attachments: [...files],
      pinned: false,
      delivered: false,
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInputValue("");
    setFiles([]);

    const typingMessage: Message = {
      id: messageId + 1,
      text: "Typing...",
      sender: "AI",
      timestamp: new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
    };

    setMessages((prevMessages) => [...prevMessages, typingMessage]);

    try {
      const response = await submitRecruiterAnswer({
        jobId: jobId,
        expertId: expertId,
        answer: inputValue,
      });
      console.log("Response:", response);

      if (response.apiStatus !== "SUCCESS") {
        console.error("API Error:", response);
        return;
      }

      await fetchMissingFields();

      const chatResponse = await getChatHistory({
        jobId: jobId,
        expertId: expertId,
      });

      const updatedChat =
      chatResponse.output.jobRequirementChatDetails.missingRequirementChatHistory;

      const newMessages = updatedChat
      .slice(-2) 
      .filter((msg: any) => !messages.some(m => m.text === msg.message))
      .map((msg: any, idx: number) => ({
        id: messages.length + idx + 1,
        text: msg.message,
        sender: msg.generatedBy === "SYSTEM" ? "AI" : "User",
        timestamp: new Date(msg.createdAt).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        delivered: true,
      }));

      setMessages((prevMessages) => [
        ...prevMessages.filter(m => m.id !== typingMessage.id),
        ...newMessages,
      ]);


      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === typingMessage.id ? {...msg, delivered:true} : msg
        )
      );
    } catch (error) {
      console.error("Failed to fetch AI reply:", error);
    }
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFiles((prev) => [...prev, ...Array.from(e.target.files!)]);
    }
  };

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);


  const handleSubmitChat = () => {
    const hasIncomplete = missingFields.some(field => !field.completed);
    if (hasIncomplete){
      message.confirm("There are questions that are still pending to answer. Do you wish to continue with that?", () => {
        props.setLoading(true);
        setTimeout(() => {
          props.setLoading(false);
          props.handleSetJobId(jobId, "reviewJobBar");
        }, 2000);
      });
    } else {
      props.handleSetJobId(jobId, "reviewJobBar");
    }
  }


  return (
    <Container>
      <FileDrop
        onDrop={handleFileDrop}
        onDragOver={() => setIsDragging(true)}
        onDragLeave={() => setIsDragging(false)}
      >
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileInput}
          multiple
          style={{ display: "none" }}
        />
        <Box sx={{ display: "flex", gap: 2 }}>
          <SidePanel>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 700,
                mb: 2,
                color: "#000000",
                fontSize: "1.1rem",
              }}
            >
              Pro Tips for Better Job Listings
            </Typography>

            <Box
              sx={{
                mb: 3,
                background: "#F7F9FD",
                p: 2,
                border: "1px solid #286BEF",
                borderRadius: "8px",
              }}
            >
              <List dense>
                {[
                  "Clear about top skills to filter strong candidates.",
                  "Mention team size & work culture to attract the right fit.",
                  "Share challenges faced in hiring for AI to suggest solutions.",
                  "Specify visa requirements early to avoid mismatches.",
                ].map((tip, index) => (
                  <ListItem key={index} sx={{ px: 0, py: 0.5 }}>
                    <ListItemIcon sx={{ minWidth: 32 }}>
                      <ProDottedCircleIcon sx={{ color: "#286BEF" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={tip}
                      primaryTypographyProps={{
                        variant: "body2",
                        sx: { fontSize: "0.9rem", color: "#286BEF" },
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>

            <Typography
              variant="h6"
              sx={{
                fontWeight: 700,
                color: "#000000",
                fontSize: "1.1rem",
              }}
            >
              AI Questions Brief
            </Typography>

            <Box
              sx={{
                borderRadius: "8px",
                px: 2,
                height: "300px",
                overflowY: "auto"
              }}
            >
              <List dense>
                {missingFields.length > 0 ? (
                  missingFields.map((field, index) => (
                    <ListItem key={index} sx={{ px: 0, py: 0.5 }}>
                      <ListItemIcon sx={{ minWidth: 32 }}>
                        {field.completed ? (
                          <CheckCircle sx={{ color: "#286BEF" }} />
                        ) : (
                          <DottedCircleIcon sx={{ color: "#D3D8E0" }} />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={field.description}
                        primaryTypographyProps={{
                          variant: "body2",
                          sx: {
                            fontSize: "0.9rem",
                            color: field.completed ? "#286BEF" : "#171A1F99",
                          },
                        }}
                      />
                    </ListItem>
                  ))
                ) : (
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "0.9rem", color: "#999" }}
                  >
                    No missing requirements.
                  </Typography>
                )}
              </List>
            </Box>
          </SidePanel>

          <ChatBox sx={{ background: isDragging ? " #171A1F99" : "#fff" }}>
            <MessageList>
              {messages.map((msg) => (
                <Box
                  key={msg.id}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: 2,
                    maxWidth: "80%",
                    alignSelf:
                      msg.sender === "User" ? "flex-end" : "flex-start",
                    position: "relative",
                    flexDirection:
                      msg.sender === "User" ? "row-reverse" : "row",
                  }}
                >
                  {msg.sender === "AI" ? (
                    <Avatar
                      sx={{
                        bgcolor: "#303f9f",
                        width: 28,
                        height: 28,
                        fontSize: 14,
                        mt: 1,
                      }}
                    >
                      <AutoFixHigh />
                    </Avatar>
                  ) : (
                    <Avatar
                      sx={{
                        bgcolor: "#286BEF1A",
                        width: 28,
                        height: 28,
                        fontSize: 14,
                        fontWeight: "bold",
                        mt: 1,
                        color: "#286BEF",
                      }}
                    >
                      AS
                    </Avatar>
                  )}

                  <Box
                    sx={{
                      p: 1.5,
                      borderRadius: "12px",
                      backgroundColor:
                        msg.sender === "User" ? "#286BEF1A" : "#fff",
                      border:
                        msg.sender === "AI" ? "1px solid #E2E8F0" : "none",
                      position: "relative",
                      maxWidth: "calc(100% - 40px)",
                      color: "#1E293B",
                    }}
                  >
                    {msg.attachments?.map((file, index) => (
                      <Chip
                        key={index}
                        icon={
                          file.type.startsWith("image/") ? (
                            <Image />
                          ) : (
                            <InsertDriveFile />
                          )
                        }
                        label={file.name}
                        sx={{ m: 0.5, fontSize: "0.75rem" }}
                      />
                    ))}

<Box
  sx={{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    gap: 2,
  }}
>
  <Typography
    variant="body2"
    sx={{
      fontSize: "0.95rem",
      color: "#1E293B",
      wordBreak: "break-word",
      whiteSpace: "pre-wrap",
      flex: 1,
    }}
  >
    {msg.text}
  </Typography>

  <Typography
    variant="caption"
    sx={{
      fontSize: "0.75rem",
      color: msg.sender === "User" ? "#286BEF" : "#94A3B8",
      flexShrink: 0,
    }}
  >
    {msg.timestamp}
  </Typography>
</Box>


                  </Box>
                </Box>
              ))}
              <div ref={messageEndRef} />
            </MessageList>

            <ChatInputContainer
              sx={{
                background: isDragging ? "#e3f2fd" : "#fff",
                borderRadius: "16px",
                padding: "4px 16px",
                border: "1px solid #E2E8F0",
                paddingTop: "12px",
                paddingBottom: "12px",
                PaddingLeft: "16px",
                paddingRight: "16px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  width: "100%",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <TextField
                    fullWidth
                    variant="standard"
                    placeholder="Write your response here..."
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        fontSize: "0.9rem",
                        "&:before, &:after": { display: "none" },
                      },
                    }}
                    sx={{ flex: 1 }}
                    size="small"
                  />
                </Box>
                <IconButton
                  color="primary"
                  onClick={handleSendMessage}
                  disabled={!inputValue && files.length === 0}
                  size="small"
                  sx={{
                    marginLeft: "auto",
                    transform: "translateY(-1px)",
                  }}
                >
                  <Send fontSize="small" />
                </IconButton>
              </Box>
            </ChatInputContainer>
          </ChatBox>
          <Button
            onClick={handleSubmitChat}
            ref={props.submitBtnRef}
            style={{ display: "none" }}
          >
            Save Job Bar
          </Button>
        </Box>
      </FileDrop>
    </Container>
  );
};

export default AIChat;
