import React, { useState, useEffect } from "react";
import { Box, Button, Tooltip } from "@mui/material";
import ProgressBarComponent from "./Components/ProgressBarComponent";
import { InfoOutlined } from "@mui/icons-material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import styled from "styled-components";
import JobCategoryCard from "./Components/JobCategoryCard";
import WeightInfoBox from "./Components/WeightInfoBox";
import ChatBoxComponent from "./Components/ChatBox";
import { GetJobBarDetails, updateJobBarDetails, PublishJob } from "./services";
import { IUpdateJobBarDetails, IAddJobComponentsProps } from "./types";
import { DEFAULT_TOKEN } from "utilities/constants";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { toast } from "react-toastify";

interface Category {
  name: string;
  values: { name: string; difficultyLevel: string }[];
  requiredWeight: number;
  insights: {
    insight: string;
    potentialRisk: string;
    suggestions: string[];
  };
}

interface MainContentProps {
  isInfoPanelOpen: boolean;
  isChatBoxOpen: boolean;
}

interface PanelProps {
  isVisible: boolean;
}

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 20px;
  margin-top: 15px;
  margin-bottom: 10px;
`;

const Title = styled.p`
  font-size: 15px;
  font-weight: 600;
  color: #333333;
  margin: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const LinkButton = styled(Button)`
  text-transform: none;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
  background: transparent;
  box-shadow: none;

  &:hover {
    background: #f0f4ff;
  }
`;

const OutlinedButton = styled(Button)`
  text-transform: none;
  font-size: 14px;
  color: #1976d2;
  padding: 5px 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 5px;
  background: transparent;
  box-shadow: none;

  &:hover {
    background: #f9fafb;
    border-color: #a6b0c3;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  transition: all 0.3s ease;
`;

const MainContent = styled.div<MainContentProps>`
  flex: ${(props) =>
    props.isInfoPanelOpen || props.isChatBoxOpen ? "0 0 65%" : "0 0 100%"};
  transition: all 0.3s ease;
  padding-right: ${(props) =>
    props.isInfoPanelOpen || props.isChatBoxOpen ? "20px" : "0"};
`;

const InfoPanelContainer = styled.div<PanelProps>`
  position: absolute;
  top: 0;
  right: 0;
  width: 35%;
  height: 50%;
  z-index: 5;
  display: ${(props) => (props.isVisible ? "block" : "none")};
`;

const ChatBoxContainer = styled.div<PanelProps>`
  position: absolute;
  top: 0;
  right: 0;
  width: 35%;
  z-index: 5;
  display: ${(props) => (props.isVisible ? "block" : "none")};
`;

const ReviewJobBar: React.FC<IAddJobComponentsProps> = (props) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [headerWeights, setHeaderWeights] = useState<number[]>([]);
  const [totalProgress, setTotalProgress] = useState<number>(0);
  const [showWeightError, setShowWeightError] = useState<boolean>(false);
  const [showWeightBox, setShowWeightBox] = useState<boolean>(false);
  const [showChatBox, setShowChatBox] = useState<boolean>(false);
  const expertId = useSelector((state: RootState) => state.auth.user?.expertId);

  const params = new URLSearchParams(window.location.search);
  const jId = params.get("jobId") || "";
  const [jobId] = useState(jId);

  useEffect(() => {
    props.setLoading(true);
  
    const fetchJobBarDetails = async () => {
      try {
        const barPayload = {
          jobId: jobId,
          expertId: expertId,
          token: DEFAULT_TOKEN,
        };
  
        const response = await GetJobBarDetails(barPayload);
  
        if (
          response.apiStatus === "SUCCESS" &&
          response.output?.jobBarCategories
        ) {
          const fetchedCategories = response.output.jobBarCategories;
          setCategories(fetchedCategories);
  
          const newWeights = fetchedCategories.map(
            (cat: Category) => cat.requiredWeight
          );
          setHeaderWeights(newWeights);
  
          const total = newWeights.reduce((sum: any, w: any) => sum + w, 0);
          setTotalProgress(total);
          setShowWeightError(total > 100);
  
          const publishPayload = {
            jobId: jobId,
            expertId: expertId,
            token: DEFAULT_TOKEN,
            jobDraftState: "PUBLISHED",
          };
  
          const publishResponse = await PublishJob(publishPayload);
  
          if (publishResponse.apiStatus !== "SUCCESS") {
            console.error("Publish job failed:", publishResponse.apiMessage);
            toast.error("Job details loaded, but publish failed.");
          }
        }
  
        props.setLoading(false);
      } catch (err) {
        props.setLoading(false);
        console.error("Error fetching job bar details:", err);
        toast.error("Something went wrong while loading job bar details.");
      }
    };
  
    fetchJobBarDetails();
  }, [jobId, expertId]);
  
  const handleSaveJobBar = async () => {
    if (totalProgress !== 100) {
      toast.error("Total weight must equal 100% before saving.");
      return;
    }

    props.setLoading(true);

    const cleanedCategories = categories.map(
      ({ name, requiredWeight, values }) => ({
        name,
        requiredWeight,
        values: values.filter(
          (v) => v.name.trim() !== "" && v.difficultyLevel.trim() !== ""
        ),
      })
    );

    const payload: IUpdateJobBarDetails = {
      expertId: expertId || "",
      jobId: jobId,
      jobBarCategories: cleanedCategories,
    };

    try {
      const response = await updateJobBarDetails(payload);
      props.setLoading(false);

      if (response.apiStatus === "SUCCESS") {


        props.handleSetJobId(jobId, "thankyou");
      } else {
        console.error("Update failed:", response.apiMessage);
        toast.error("Something went wrong, please try again!");
      }
    } catch (error) {
      console.error("Error updating job bar:", error);
      toast.error("Something went wrong, please try again!");
    }
  };

  const updateCategory = (
    name: string,
    newWeight: number,
    newValues: { name: string; difficultyLevel: string }[]
  ) => {
    const updated = categories.map((cat) =>
      cat.name === name
        ? { ...cat, requiredWeight: newWeight, values: newValues }
        : cat
    );
    setCategories(updated);

    const weights = updated.map((cat) => cat.requiredWeight);
    setHeaderWeights(weights);

    const sum = weights.reduce((a, b) => a + b, 0);
    setTotalProgress(sum);
    setShowWeightError(sum > 100);
  };

  return (
    <Box
      sx={{
        marginTop: "30px",
        width: "99%",
        px: 0,
        paddingBottom: "20px",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <ContentContainer>
        <MainContent
          isInfoPanelOpen={showWeightBox}
          isChatBoxOpen={showChatBox}
        >
          <Box sx={{ marginLeft: "20px" }}>
            <ProgressBarComponent
              headerWeights={headerWeights}
              totalProgress={totalProgress}
              categoryNames={categories.map((cat) => cat.name)}
              showWeightError={showWeightError}
            />
          </Box>

          <HeaderWrapper>
            <Title>Job Bar categories</Title>

            {!showWeightBox && !showChatBox && (
              <ButtonContainer>
                <Tooltip
                  title="Learn how weights affect candidate matching"
                  arrow
                >
                  <LinkButton
                    sx={{ color: "#6D758D" }}
                    startIcon={<InfoOutlined fontSize="small" />}
                    onClick={() => setShowWeightBox(true)}
                  >
                    Learn how weights work?
                  </LinkButton>
                </Tooltip>

                {/* <OutlinedButton
                  variant="outlined"
                  sx={{
                    fontWeight: "600",
                    background: "#ffffff",
                    border: "1px solid #1A3070",
                  }}
                  startIcon={<AutoAwesomeIcon fontSize="small" />}
                  onClick={() => setShowChatBox(true)}
                >
                  Optimize Candidate Matching
                </OutlinedButton> */}
              </ButtonContainer>
            )}
          </HeaderWrapper>

          <Box sx={{ width: "99%", marginLeft: "20px" }}>
            {categories.map((category, index) => (
              <JobCategoryCard
                key={index}
                category={category}
                showWeightBox={showWeightBox}
                showChatBox={showChatBox}
                updateCategory={updateCategory}
              />
            ))}
          </Box>
          <Button
            onClick={handleSaveJobBar}
            ref={props.submitBtnRef}
            style={{ display: "none" }}
          >
            Save Job Bar
          </Button>
        </MainContent>

        <InfoPanelContainer isVisible={showWeightBox}>
          <WeightInfoBox onClose={() => setShowWeightBox(false)} />
        </InfoPanelContainer>

        <ChatBoxContainer isVisible={showChatBox}>
          <ChatBoxComponent onClose={() => setShowChatBox(false)} />
        </ChatBoxContainer>
      </ContentContainer>
    </Box>
  );
};

export default ReviewJobBar;
