import React from "react";
import { Box, Paper, Typography, Tooltip } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

interface ProgressBarProps {
  headerWeights: number[];
  totalProgress: number;
  categoryNames: string[];
  showWeightError: boolean
}

const ProgressBarComponent: React.FC<ProgressBarProps> = ({
  headerWeights,
  totalProgress,
  categoryNames,
  showWeightError,
}) => {
  const totalWeight = headerWeights.reduce((acc, val) => acc + val, 0);
  const normalizedWeights = headerWeights.map((weight) =>
    totalWeight === 0 ? 0 : (weight / totalWeight) * 100
  );

  const getProgressBarColor = (progress: number) => {
    if (progress === 100) return "#546FFF";
    if (progress >= 75) return "#325CD6";
    if (progress >= 50) return "#4F70E5";
    return "#A0B4FF";
  };

  return (
    <Paper
      elevation={0}
      sx={{
        p: 3,
        mb: 3,
        borderRadius: "8px",
        border: "1px solid #D9DDE3",
        backgroundColor: "#f9faff",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            width: 55,
            height: 55,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "bold",
            color: "#1976d2",
            mr: 3,
            flexShrink: 0,
            position: "relative",
          }}
        >
          <svg width="55" height="55">
            <circle cx="27.5" cy="27.5" r="22" stroke="#e6e6e6" strokeWidth="4" fill="none" />
            <circle
              cx="27.5"
              cy="27.5"
              r="22"
              stroke="#546FFF"
              strokeWidth="4"
              fill="none"
              strokeDasharray="140"
              strokeDashoffset={140 - (totalProgress / 100) * 140}
              strokeLinecap="round"
              style={{ transition: "stroke-dashoffset 0.5s ease-in-out" }}
            />
          </svg>
          <Typography
            sx={{
              position: "absolute",
              fontSize: "14px",
              fontWeight: "bold",
              color: "#546FFF",
            }}
          >
            {totalProgress}%
          </Typography>
        </Box>

        <Box sx={{ flex: 1, display: "flex", alignItems: "center", gap: 1 }}>
          {normalizedWeights.map((weight, index) => (
            <Tooltip
              key={index}
              title={`${categoryNames[index]}: ${headerWeights[index]}%`}
              arrow
            >
              <Box
                sx={{
                  width: `${weight}%`,
                  height: "17px",
                  backgroundColor: "#d0d7ff",
                  borderRadius: "8px",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: getProgressBarColor(totalProgress),
                    borderRadius: "6px",
                    transition: "width 0.3s ease-in-out, background-color 0.3s ease-in-out",
                  }}
                />
              </Box>
            </Tooltip>
          ))}

        </Box>
      </Box>
      {showWeightError && (
  <Typography
    sx={{
      mt: 1,
      ml: "80px",
      fontSize: "13px",
      fontWeight: 500,
      color: "#F04438",
    }}
  >
    Total weight exceeds 100%. Adjust other criteria to balance.
  </Typography>
)}

      <Box sx={{ display: "flex", alignItems: "center", mt: 1, marginLeft: "80px" }}>
        <InfoOutlined sx={{ color: "#1976d2", fontSize: 14, mr: 1 }} />
        <Typography
          variant="body2"
          color="#286BEF"
          sx={{ fontSize: "12px", fontWeight: 500 }}
        >
          Experience is important, but relying too much on it may filter out talented candidates
          with strong skills but fewer years in the industry. Consider reducing to widen the talent
          pool.
        </Typography>
      </Box>
    </Paper>
  );
};

export default ProgressBarComponent;
