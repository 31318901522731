import React from "react";
import { Box, Typography, Paper, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

interface WeightInfoBoxProps {
  onClose: () => void;
}

const WeightInfoBox: React.FC<WeightInfoBoxProps> = ({ onClose }) => {
  return (
    <Paper
      sx={{
        p: 0,
        borderRadius: 2,
        border: "1px solid #E0E4E8",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.05)",
        mb: 2,
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
          borderBottom: "1px solid #E0E4E8",
        }}
      >
        <Typography variant="h6" fontWeight="600" fontSize="16px">
          How criteria weight works?
        </Typography>
        <IconButton onClick={onClose} sx={{ color: "#656E81", p: 0.5 }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box sx={{ p: 0 }}>
        <Box
          sx={{
            backgroundColor: "#9198E5",
            backgroundImage:
              "linear-gradient(135deg, #9198E5 0%, #A5ABF5 100%)",
            color: "white",
            height: "200px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            p: 3,
          }}
        >
          <Typography
            variant="body2"
            sx={{ position: "absolute", top: 20, left: 20 }}
          >
            Part 1
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
            How to get started
          </Typography>
          <Box
            sx={{
              width: "60px",
              height: "60px",
              borderRadius: "50%",
              backgroundColor: "rgba(255, 255, 255, 0.3)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PlayCircleOutlineIcon sx={{ fontSize: "40px" }} />
          </Box>
        </Box>
      </Box>

      <Box sx={{ p: 3 }}>
        <Typography
          variant="h6"
          fontWeight="600"
          fontSize="16px"
          sx={{ mb: 2 }}
        >
          AI Instructions for your Job
        </Typography>

        <Typography fontSize="14px" color="#4A5568" sx={{ mb: 2 }}>
          AI can help streamline job descriptions by optimizing wording,
          ensuring clarity, and aligning responsibilities with industry
          standards.
        </Typography>

        <Typography fontSize="14px" color="#4A5568" sx={{ mb: 1 }}>
          <Box
            component="span"
            sx={{ color: "#E53E3E", fontWeight: "600", mr: 1 }}
          >
            📌
          </Box>
          <Box component="span" sx={{ fontWeight: "600" }}>
            Step 1: Understanding Weights:
          </Box>
        </Typography>
        <Typography fontSize="14px" color="#4A5568" sx={{ mb: 2, pl: 3 }}>
          <Box component="span" sx={{ mr: 1 }}>
            📝
          </Box>
          "Weights distribute importance across different hiring criteria.
          Higher weight = more impact on candidate ranking.
        </Typography>

        <Typography fontSize="14px" color="#4A5568" sx={{ mb: 1 }}>
          <Box
            component="span"
            sx={{ color: "#E53E3E", fontWeight: "600", mr: 1 }}
          >
            📌
          </Box>
          <Box component="span" sx={{ fontWeight: "600" }}>
            Step 2: How the AI Matches Candidates:
          </Box>
        </Typography>
        <Typography fontSize="14px" color="#4A5568" sx={{ mb: 0.5, pl: 3 }}>
          <Box component="span" sx={{ color: "#38A169", mr: 1 }}>
            ✅
          </Box>
          Candidate's profile is scored against each weighted factor
        </Typography>
        <Typography fontSize="14px" color="#4A5568" sx={{ mb: 2, pl: 3 }}>
          <Box component="span" sx={{ color: "#38A169", mr: 1 }}>
            ✅
          </Box>
          Final score is a weighted sum of all factors
        </Typography>

        <Typography fontSize="14px" color="#4A5568" sx={{ mb: 1 }}>
          <Box
            component="span"
            sx={{ color: "#E53E3E", fontWeight: "600", mr: 1 }}
          >
            📌
          </Box>
          <Box component="span" sx={{ fontWeight: "600" }}>
            Step 3: Example Calculation:
          </Box>
        </Typography>
        <Typography fontSize="14px" color="#4A5568" sx={{ mb: 0.5, pl: 3 }}>
          <Box component="span" sx={{ color: "#38A169", mr: 1 }}>
            ✅
          </Box>
          Skills (50%) – Candidate matches 90% → Earns 45 points
        </Typography>
        <Typography fontSize="14px" color="#4A5568" sx={{ mb: 0.5, pl: 3 }}>
          <Box component="span" sx={{ color: "#38A169", mr: 1 }}>
            ✅
          </Box>
          Experience (30%) – Candidate matches 70% → Earns 21 points
        </Typography>
        <Typography fontSize="14px" color="#4A5568" sx={{ mb: 2, pl: 3 }}>
          <Box component="span" sx={{ color: "#718096", mr: 1 }}>
            ➕
          </Box>
          Final Fit Score = 45 + 21 + 12 = 78% fit
        </Typography>
      </Box>
    </Paper>
  );
};

export default WeightInfoBox;
