import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";

const UploadContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isDragging",
})<{ isDragging: boolean }>(({ isDragging }) => ({
  borderRadius: "12px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
  background: isDragging ? "#E3F2FD" : "#F7F9FD",
  transition: "background 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  height: "180px",
}));

interface FileUploadProps {
  onFileChange?: (file: File | null) => void; // 🔥 Add this prop
}

const FileUpload: React.FC<FileUploadProps> = ({ onFileChange }) => {
  const [file, setFile] = useState<File | null>(null);
  const [isDragging, setIsDraging] = useState<boolean>(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0] || null;
    setFile(selectedFile);
    if (onFileChange) onFileChange(selectedFile);
  };

  const handleRemoveFile = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setFile(null);
    if (onFileChange) onFileChange(null);
  };

  const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraging(true);
  };

  const handleDragLeave = () => {
    setIsDraging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraging(false);
    if (event.dataTransfer.files.length > 0) {
      setFile(event.dataTransfer.files[0]);
    }
  };

  return (
    <UploadContainer
      isDragging={isDragging}
      onDragOver={handleDrag}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={() => document.getElementById("fileInput")?.click()}
    >
      <input
        id="fileInput"
        type="file"
        hidden
        accept=".pdf"
        onChange={handleFileChange}
      />
      {file ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginTop="40px"
          gap={2}
        >
          <Typography variant="body2">{file.name}</Typography>
          <Button
            size="small"
            color="error"
            onClick={handleRemoveFile}
            startIcon={<DeleteIcon />}
          >
            Remove
          </Button>
        </Box>
      ) : (
        <>
          <CloudUploadIcon
            sx={{ color: "#47546766", marginBottom: "10px", marginTop: "20px" }}
            fontSize="large"
          />
          <Typography variant="body2">
            <span style={{ color: "#325CD680", fontWeight: "600" }}>
              Click to upload
            </span>{" "}
            or drag and drop .pdf
          </Typography>
        </>
      )}
    </UploadContainer>
  );
};

export default FileUpload;
