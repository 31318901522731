import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Radio, CircularProgress } from "@mui/material";
import FileUpload from "./Components/FileUpload";
import InterviewRound from "./Components/InterviewRound";
import RichTextEditor from "components/Common/Editors/RichTextEditor";
import { MenuItem } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  IAddJob,
  IClientInterviewProcess,
  IAddJobComponentsProps,
} from "./types";
import { OptionsDataType } from "types/Jobs";
import pdfToText from "react-pdftotext";
import { toast } from "react-toastify";
import { compensationOptions, timeArray, timeZones } from "pages/B2B/constants";
import {
  currencyList,
  employmentTypes,
  jobTypes,
} from "containers/HiringManager/Jobs.util";
import JobLocationSelector from "pages/B2B/Jobs/CreateJob/JobLocationSelector";
import { useSelector } from "react-redux";
import {
  getFormatLocationForSave,
  getLocationInOptionDataFormat,
} from "containers/HiringManager/Jobs.util";
import { RootState } from "store";
import { createJob, getJobDetails, updateJob } from "./services";
import {
  Label,
  FormikStyledTextField,
} from "./Components/FormikStyledTextField";
import { OverlayLoader } from "components/Common/Loader/loaderWithOverlay";

interface IProps extends IAddJobComponentsProps {
  sourcingType: string;
}

const JobDetails: React.FC<IProps> = (props) => {
  const [description, setDescription] = useState("");
  const [interviewRounds, setInterviewRounds] = useState([{ id: 1 }]);
  const [jobDescSource, setJobDescSource] = useState<"manual" | "upload">(
    "manual"
  );
  const expertId = useSelector((state: RootState) => state.auth.user.expertId);
  const [sourcingType, setSourcingType] = useState(props.sourcingType);
  const [trackId, setTrackId] = useState<string>("");
  const [JDLoading, setJDLoading] = useState<boolean>(false);

  const params = new URLSearchParams(window.location.search);
  const jId = params.get("jobId") || "";
  const [jobId] = useState(jId);
  const isEdit = Boolean(jId);

  const defaultInitialValues = {
    jobTitle: "",
    openPositions: 0,
    jobType: "",
    employmentType: "",
    currency: "",
    minSalary: 0,
    maxSalary: 0,
    description: "",
    jobDescription: "",
    interviewRounds: [{ title: "", questions: "", criteria: "" }],
    parsedPdfText: "",
    numberOfDaysOfficeEveryWeek: 0,
    selectedLocations: [] as OptionsDataType[],
    equity: "",
    fileName: "",
    timezone: "",
    startTime: "",
    endTime: "",
  };

  const [initialValues, setInitialValues] = useState(defaultInitialValues);

  useEffect(() => {
    if (isEdit && jobId && expertId) {
      setJDLoading(true);

      getJobDetails({
        jobId: jobId,
        expertId: expertId,
      })
        .then((data) => {
          if (data.apiStatus === "SUCCESS") {
            const output = data.output;

            setJobDescSource("manual");

            const mappedValues = {
              jobTitle: output.jobTitle || output.title || "",
              openPositions: output.openPositions || 0,
              jobType: output.jobType || "",
              employmentType: output.employmentType || "",
              currency: output.currency || "",
              minSalary: output.minSalary || 0,
              maxSalary: output.maxSalary || 0,
              description: output?.trackDetails?.jobDescription,
              jobDescription: output?.trackDetails?.jobDescription,
              parsedPdfText: "",
              interviewRounds: output.trackDetails?.clientInterviewProcess
                ? output.trackDetails.clientInterviewProcess.map(
                    (round: any) => ({
                      title: round.title,
                      questions: round.question,
                      criteria: round.criteria,
                    })
                  )
                : [{ title: "", questions: "", criteria: "" }],
              numberOfDaysOfficeEveryWeek:
                output.numberOfDaysOfficeEveryWeek ?? 0,
              selectedLocations:
                getLocationInOptionDataFormat(output.locations) || [],
              equity: output.equity || "",
              fileName: "",
              timezone: output.timezone || "",
              startTime: output.startTime || "",
              endTime: output.endTime || "",
            };

            setTrackId(output.trackId);
            setSourcingType(
              output.sourcingType || sourcingType || "SOURCE_TALENT"
            );
            setInitialValues(mappedValues);

            setInterviewRounds(
              mappedValues.interviewRounds.map((_: any, i: number) => ({
                id: i + 1,
              }))
            );
          } else {
            toast.error("Failed to load job details: " + data.apiMessage);
          }
        })
        .catch(() => {
          toast.error("Error fetching job details");
        })
        .finally(() => setJDLoading(false));
    }
  }, [isEdit, jobId, expertId]);

  const addInterviewRound = () => {
    setInterviewRounds([
      ...interviewRounds,
      { id: interviewRounds.length + 1 },
    ]);
  };

  const removeInterviewRound = (id: number) => {
    if (interviewRounds.length > 1) {
      setInterviewRounds(interviewRounds.filter((round) => round.id !== id));
    }
  };

  const parsePDF = async (file: File): Promise<string> => {
    return pdfToText(file);
  };

  const handleFileChange = (
    file: any,
    setFieldValue: (name: string, value: string) => void
  ) => {
    const maxSize = 5 * 1024 * 1024;
    if (file?.size && file.size > maxSize) {
      toast.error("File size should be less then 5MB");
      return;
    }
    if (file) {
      setFieldValue("fileName", file.name);
      props.setLoading(true);
      parsePDF(file)
        .then((res) => {
          setFieldValue("parsedPdfText", res);
          setFieldValue("jobDescription", res);
          setJobDescSource("upload");
          setDescription(res);
        })
        .catch(() => {
          toast.error("Could not parse PDF.");
        })
        .finally(() => {
          props.setLoading(false);
        });
    } else {
      setFieldValue("parsedPdfText", "");
      setFieldValue("jobDescription", "");
    }
  };

  useEffect(() => {
    const storedPdfText = localStorage.getItem("parsedPdfText");
    if (storedPdfText) {
      setDescription(storedPdfText);
      setJobDescSource("upload");
    }
  }, []);

  const validationSchemaIAS = Yup.object({
    jobTitle: Yup.string().required("Job title is required"),
    description: Yup.string(),
    parsedPdfText: Yup.string().nullable(),
    jobDescription: Yup.mixed().test(
      "jobDescription-required",
      "Please provide either a job description or upload a PDF file.",
      function () {
        const { description, parsedPdfText } = this.parent;
        return Boolean(description?.trim() || parsedPdfText?.trim());
      }
    ),
    interviewRounds: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required("Interview title is required"),
        questions: Yup.string().required("Interview Question is required"),
        criteria: Yup.string(),
      })
    ),
  });

  const validationSchemaSource = Yup.object({
    jobTitle: Yup.string().required("Job title is required"),
    openPositions: Yup.number()
      .typeError("Must be a number")
      .min(1, "At least 1 position required")
      .required("Number of positions is required"),
    jobType: Yup.string().required("Job type is required"),
    selectedLocations: Yup.array<OptionsDataType[]>().when("jobType", {
      is: (jobType) => jobType === "HYBRID" || jobType === "ONSITE",
      then: (schema: any) =>
        schema.test(
          "is-valid-description",
          "This field is required",
          (value: any) => {
            if (!value || value.length === 0) {
              return false;
            }
            return true;
          }
        ),
      otherwise: (schema: any) => schema.nullable(),
    }),
    numberOfDaysOfficeEveryWeek: Yup.number()
      .nullable()
      .when("jobType", {
        is: (jobType) => jobType === "HYBRID",
        then: (schema: any) =>
          schema
            .required("This field is required")
            .min(1, "Number of days cannot be less than 1")
            .max(4, "Number of days cannot be more than 4"),
        otherwise: (schema: any) => schema.nullable(),
      }),
    employmentType: Yup.string().required("Employment type is required"),
    equity: Yup.string().required("Compensation type is required"),
    currency: Yup.string().required("Currency is required"),
    maxSalary: Yup.number()
      .nullable()
      .when("equity", {
        is: (equity) => equity === "CASH_ANDOR_EQUITY",
        then: (schema: any) =>
          schema
            .required("Min and Max Compensation is required")
            .min(0, "Compensation cannot be less then 0"),
        otherwise: (schema: any) => schema.nullable(),
      }),
    minSalary: Yup.number()
      .nullable()
      .when("equity", {
        is: (equity) => equity === "CASH_ANDOR_EQUITY",
        then: (schema: any) =>
          schema
            .required("Min and Max Compensation is required")
            .min(0, "Compensation cannot be less then 0")
            .min(0, "Compensation cannot be less then 0")
            .notOneOf(
              [Yup.ref("maxSalary")],
              "Min salary cannot be equal to Max salary"
            )
            .max(
              Yup.ref("maxSalary"),
              "Min salary cannot be greater than Max salary"
            ),
        otherwise: (schema: any) => schema.nullable(),
      }),
    description: Yup.string(),
    parsedPdfText: Yup.string().nullable(),
    jobDescription: Yup.mixed().test(
      "jobDescription-required",
      "Please provide either a job description or upload a PDF file.",
      function () {
        const { description, parsedPdfText } = this.parent;
        return Boolean(description?.trim() || parsedPdfText?.trim());
      }
    ),
    interviewRounds: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required("Interview title is required"),
        questions: Yup.string().required("Interview Question is required"),
        criteria: Yup.string(),
      })
    ),
  });

  if (JDLoading) {
    return (
      <Box
          sx={{
            width: "99%",
            padding: "20px",
            border: "1px solid lightblue",
            borderRadius: "9px",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "30px",
            background: "#fff",
          }}
        >
          <CircularProgress size={20} color="inherit" sx={{ mr: 1 }} />
          Fetching Job Details...
      </Box>
    );
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={
        (sourcingType === "SOURCE_TALENT" && validationSchemaSource) ||
        validationSchemaIAS
      }
      onSubmit={(values) => {
        const clientInterviewProcess: IClientInterviewProcess[] =
          values.interviewRounds.map((round, index) => ({
            title: round.title,
            question: round.questions,
            criteria: round.criteria,
            round: index + 1,
          }));

        const locations = getFormatLocationForSave(values.selectedLocations);

        const formattedData: IAddJob = {
          async: false,
          currency: values.currency,
          timezone: values.timezone,
          employmentType: values.employmentType,
          equity: values.equity,
          jobAccess: "PRIVATE",
          jobTitle: values.jobTitle,
          jobType: values.jobType,
          locations: locations,
          selectedLocations: values.selectedLocations as OptionsDataType[],
          maxSalary: values.maxSalary,
          minSalary: values.minSalary,
          openPositions: values.openPositions,
          selectionSteps: [],
          title: values.jobTitle,
          fileName: values.fileName || "",
          jobDescription: values.jobDescription || "",
          clientInterviewProcess: clientInterviewProcess,
          startTime: values.startTime,
          endTime: values.endTime,
          numberOfDaysOfficeEveryWeek:
            values.numberOfDaysOfficeEveryWeek || null,
          expertId: expertId,
          sourcingType: sourcingType,
        };

        props.setLoading(true);

        const promise =
          isEdit && jobId
            ? updateJob(jobId, trackId, formattedData)
            : createJob(formattedData);

        promise
          .then((res) => {
            props.setLoading(false);
            if (res && res.output && res.apiStatus === "SUCCESS") {
              const jobDetails = res.output.jobEntityResponse;
              let jId = (isEdit && jobId) || jobDetails?.jobId;
              if (sourcingType === "SOURCE_TALENT") {
                props.handleSetJobId(jId, "aiChat");
              } else {
                props.handleSetJobId(jId, "thankyou");
              }
            } else {
              console.error("Error", res);
              toast.error(
                res.apiMessage ||
                  "Something went wrong while saving. Please try again!"
              );
            }
          })
          .catch((err) => {
            props.setLoading(false);
            console.error("Error", err);
            toast.error(
              err.message ||
                "Something went wrong while saving. Please try again!"
            );
          });
      }}
    >
      {({ values, setFieldValue, errors }) => (
        <Form>
          <OverlayLoader loading={JDLoading} />
          <Box
            sx={{
              width: "99%",
              padding: "20px",
              border: "1px solid lightblue",
              borderRadius: "9px",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "30px",
              background: "#fff",
            }}
          >
            <Box>
              <Typography fontWeight="bold" sx={{ mb: 2, fontSize: "16px" }}>
                Basic Job Details
              </Typography>
              <Box display="flex" gap={2} mt={2}>
                <Box sx={{ width: "100%" }}>
                  <Label>Job Title</Label>
                  <Field
                    component={FormikStyledTextField}
                    name="jobTitle"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Enter Job Title"
                  />
                </Box>
                {sourcingType === "SOURCE_TALENT" && (
                  <>
                    <Box sx={{ width: "48.5%" }}>
                      <Label>No. of Positions: </Label>
                      <Box>
                        <Field
                          component={FormikStyledTextField}
                          name="openPositions"
                          type="number"
                          variant="outlined"
                          fullWidth
                          size="small"
                          placeholder="0"
                        />
                      </Box>
                    </Box>
                    <Box sx={{ width: "50%" }}>
                      <Label>Job Type</Label>
                      <Field
                        component={FormikStyledTextField}
                        name="jobType"
                        select
                        fullWidth
                        size="small"
                        placeholder="Select Job Type"
                      >
                        {jobTypes.map((type, idx) => (
                          <MenuItem value={type.key}>{type.value}</MenuItem>
                        ))}
                      </Field>
                    </Box>
                  </>
                )}
                {sourcingType === "SOURCE_TALENT" &&
                  values.jobType !== "REMOTE" && (
                    <Box sx={{ width: "70%" }}>
                      <Label>Job Location</Label>
                      <JobLocationSelector
                        disabled={false}
                        handleChangeLocation={setFieldValue}
                        keyName="selectedLocations"
                        selectedLocations={values.selectedLocations}
                      />
                    </Box>
                  )}
                {(sourcingType === "SOURCE_TALENT" &&
                  values.jobType === "HYBRID" && (
                    <Box sx={{ width: "30%" }}>
                      <Label>Employee must visit</Label>
                      <Field
                        component={FormikStyledTextField}
                        name="numberOfDaysOfficeEveryWeek"
                        type="number"
                        variant="outlined"
                        fullWidth
                        size="small"
                        placeholder="0"
                      />
                    </Box>
                  )) ||
                  null}
                {sourcingType === "SOURCE_TALENT" &&
                  values.jobType === "REMOTE" && (
                    <>
                      <Box sx={{ width: "50%" }}>
                        <Label>Time Zone:</Label>
                        <Field
                          component={FormikStyledTextField}
                          name="timezone"
                          select
                          variant="outlined"
                          fullWidth
                          size="small"
                        >
                          {timeZones.map((timeZone, idx) => {
                            return (
                              <MenuItem key={timeZone + idx} value={timeZone}>
                                {timeZone}
                              </MenuItem>
                            );
                          })}
                        </Field>
                      </Box>

                      <Box sx={{ width: "25%" }}>
                        <Label>Start Time:</Label>
                        <Field
                          component={FormikStyledTextField}
                          name="startTime"
                          select
                          variant="outlined"
                          fullWidth
                          size="small"
                        >
                          {timeArray.map((time, idx) => {
                            return (
                              <MenuItem key={time.key + idx} value={time.key}>
                                {time.label}
                              </MenuItem>
                            );
                          })}
                        </Field>
                      </Box>
                      <Box sx={{ width: "25%" }}>
                        <Label>End Time:</Label>
                        <Field
                          component={FormikStyledTextField}
                          name="endTime"
                          select
                          variant="outlined"
                          fullWidth
                          size="small"
                        >
                          {timeArray.map((time, idx) => {
                            return (
                              <MenuItem key={time.key + idx} value={time.key}>
                                {time.label}
                              </MenuItem>
                            );
                          })}
                        </Field>
                      </Box>
                    </>
                  )}
              </Box>
              {sourcingType === "SOURCE_TALENT" && (
                <Box display="flex" gap={2} mt={2}>
                  <Box sx={{ width: "100%" }}>
                    <Label>Employment Type</Label>
                    <Field
                      component={FormikStyledTextField}
                      name="employmentType"
                      select
                      fullWidth
                      size="small"
                      placeholder="Employment Type"
                    >
                      {employmentTypes.map((type, idx) => (
                        <MenuItem value={type.key}>{type.value}</MenuItem>
                      ))}
                    </Field>
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <Label>Compensation Type</Label>
                    <Field
                      component={FormikStyledTextField}
                      name="equity"
                      select
                      fullWidth
                      size="small"
                      placeholder="Compensation Type"
                    >
                      {compensationOptions.map((type, idx) => (
                        <MenuItem value={type.key}>{type.label}</MenuItem>
                      ))}
                    </Field>
                  </Box>

                  <Box sx={{ width: "100%" }}>
                    <Label>Currency</Label>
                    <Field
                      component={FormikStyledTextField}
                      name="currency"
                      select
                      fullWidth
                      size="small"
                      placeholder="Currency"
                    >
                      {currencyList.map((currency, idx) => {
                        return (
                          <MenuItem
                            key={currency.code + idx}
                            value={currency.code}
                          >
                            {currency.code}
                          </MenuItem>
                        );
                      })}
                    </Field>
                  </Box>

                  {values.equity === "CASH_ANDOR_EQUITY" && (
                    <Box sx={{ width: "48.5%" }}>
                      <Label>Annual Salary</Label>
                      <Box display="flex" gap={2}>
                        <Field
                          component={FormikStyledTextField}
                          name="minSalary"
                          variant="outlined"
                          fullWidth
                          size="small"
                          type="number"
                          placeholder="Min"
                        />
                        <Field
                          component={FormikStyledTextField}
                          name="maxSalary"
                          variant="outlined"
                          fullWidth
                          size="small"
                          type="number"
                          placeholder="Max"
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
            </Box>

            <Box sx={{ mt: 4 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Radio
                  checked={jobDescSource === "manual"}
                  onChange={() => {
                    setJobDescSource("manual");
                    setFieldValue("parsedPdfText", "");
                    setFieldValue("jobDescription", description);
                  }}
                  value="manual"
                  color="primary"
                />
                <Typography
                  variant="body1"
                  fontSize="14px"
                  fontWeight="500"
                  color="#333333"
                >
                  Job / Assessment Description
                </Typography>
              </Box>
              <Box
                sx={{
                  mt: 1,
                  border: "1px solid #D1D5DB",
                  borderRadius: "8px",
                  padding: "10px",
                  background: "#FFFFFF",
                  boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
                  marginBottom: "15px",
                }}
              >
                <Field
                  className="form-control"
                  name="description"
                  id="job-description"
                  type="text"
                >
                  {() => (
                    <RichTextEditor
                      value={values.description}
                      disabled={false}
                      onChange={(val: any) => {
                        setDescription(val);
                        setFieldValue("description", val);
                        setFieldValue("jobDescription", val);
                      }}
                      id={"job-description-rte"}
                      customStyles={{
                        height: "200px",
                        minHeight: "200px",
                        boxShadow: "none",
                        background: "white",
                        border: "none",
                        resize: "none",
                        padding: "10px",
                      }}
                      placeholder="Enter Job Description"
                    />
                  )}
                </Field>

                {/* <RichTextEditor
                  value={values.description}
                  id="job-description"
                  onChange={(val: any) => {
                    setDescription(val);
                    setFieldValue("description", val);
                    setFieldValue("jobDescription", val);
                  }}
                  customStyles={{
                    height: "200px",
                    minHeight: "200px",
                    boxShadow: "none",
                    background: "white",
                    border: "none",
                    resize: "none",
                    padding: "10px",
                  }}
                  disabled={false}
                /> */}
              </Box>
            </Box>

            <Box display="flex" alignItems="center">
              <Radio
                checked={jobDescSource === "upload"}
                onChange={() => setJobDescSource("upload")}
                color="primary"
              />
              <Typography
                variant="body1"
                fontSize="14px"
                fontWeight="500"
                color="#98A2B3"
                lineHeight={1.2}
              >
                Upload a file containing the Job Description
              </Typography>
            </Box>
            <Box>
              <FileUpload
                onFileChange={(file) => handleFileChange(file, setFieldValue)}
              />
              <Field type="hidden" name="parsedPdfText" />
              <Field type="hidden" name="fileName" />
            </Box>

            <Field type="hidden" name="jobDescription" />
            <Box
              sx={{
                mt: 4,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography fontWeight="500" fontSize="16px" color="#171717">
                Client Interview Process (Add all interview rounds)
              </Typography>
              <Button
                onClick={addInterviewRound}
                sx={{
                  textTransform: "none",
                  borderColor: "#007BFF",
                  color: "#007BFF",
                  "&:hover": { background: "#007BFF", color: "#fff" },
                  fontWeight: "700",
                  fontSize: "16px",
                }}
              >
                + Add new round
              </Button>
            </Box>
            {interviewRounds.map((round, index) => (
              <InterviewRound
                key={round.id}
                roundNumber={index + 1}
                roundIndex={index}
                onDelete={() => removeInterviewRound(round.id)}
              />
            ))}
            {/* Submit Button */}
            <Button
              type="submit"
              ref={props.submitBtnRef}
              style={{ display: "none" }}
            >
              Submit
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default JobDetails;
