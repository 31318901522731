import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Card,
  TextField,
  IconButton,
  Avatar,
  Typography,
  Chip,
  Tooltip,
} from "@mui/material";
import {
  Send,
  Mic,
  AttachFile,
  InsertDriveFile,
  Image,
  DoneAll,
  AutoFixHigh,
} from "@mui/icons-material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";



const ChatBox = styled(Card)({
  borderRadius: "12px",
  display: "flex",
  flexDirection: "column",
  background: "#fff",
  boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
  overflow: "hidden",
  position: "relative",
  border: "1px solid #82B3E6",
  height: "80vh",
  padding: "10px",
});

const MessageList = styled(Box)({
  flexGrow: 1,
  overflowY: "auto",
  padding: "16px",
  display: "flex",
  flexDirection: "column",
  gap: "12px",
});

const ChatInputContainer = styled(Box)({
  position: "sticky",
  bottom: 0,
  display: "flex",
  alignItems: "center",
  gap: 8,
  padding: "12px",
  border: "1px solid #CBD5E1",
  background: "#fff",
  transition: "all 0.3s ease",
  borderRadius: "30px",
});

interface Message {
    id: number;
    text?: string;
    sender: "AI" | "User";
    timestamp: string;
    delivered?: boolean;
    attachments?: File[];
    candidateProfile?: boolean;
}




interface ChatBoxProps {
  onClose: () => void;
}

const ChatBoxComponent: React.FC<ChatBoxProps> = ({  onClose }) => {
  const [inputValue, setInputValue] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const messageEndRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<Message[]>([
      {
        id: 1,
        text: "Hello! How can I assist you today?",
        sender: "AI",
        timestamp: new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
      },
    ]);
  const handleSendMessage = () => {
    if (!inputValue.trim() && files.length === 0) return;

    const newMessage: Message = {
      id: messages.length + 1,
      text: inputValue,
      sender: "User",
      timestamp: new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
      attachments: [...files],
    };

    setMessages([...messages, newMessage]);
    setInputValue("");
    setFiles([]);

    setTimeout(() => {
      setMessages((prev) =>
        prev.map((msg) =>
          msg.id === newMessage.id ? { ...msg, delivered: true } : msg
        )
      );
    }, 2000);
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFiles((prev) => [...prev, ...( e.target.files ? Array.from(e.target.files) : [])]);
    }
  };

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <ChatBox>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
          borderBottom: "1px solid #E0E4E8",
        }}
      >
        <Typography variant="h6" fontWeight="600" fontSize="16px">
          Ensure the perfect leads matches
        </Typography>
        <IconButton onClick={onClose} sx={{ color: "#656E81", p: 0.5 }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <MessageList>
        {messages.map((msg) => (
          <Box
            key={msg.id}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: 2,
              maxWidth: "80%",
              alignSelf: msg.sender === "User" ? "flex-end" : "flex-start",
              position: "relative",
              flexDirection: msg.sender === "User" ? "row-reverse" : "row",
            }}
          >
            {msg.sender === "AI" ? (
              <Avatar sx={{ bgcolor: "#303f9f", width: 28, height: 28, fontSize: 14, mt: 1 }}>
                <AutoFixHigh />
              </Avatar>
            ) : (
              <Avatar
                sx={{
                  bgcolor: "#286BEF1A",
                  width: 28,
                  height: 28,
                  fontSize: 14,
                  fontWeight: "bold",
                  mt: 1,
                  color: "#286BEF",
                }}
              >
                AS
              </Avatar>
            )}

            <Box
              sx={{
                p: 1.5,
                borderRadius: "12px",
                backgroundColor: msg.sender === "User" ? "#286BEF1A" : "#fff",
                border: msg.sender === "AI" ? "1px solid #E2E8F0" : "none",
                position: "relative",
                maxWidth: "calc(100% - 40px)",
                color: "#1E293B",
              }}
            >

              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography variant="body2" sx={{ fontSize: "0.95rem" }}>
                  {msg.text}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1, ml: 2 }}>
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: "0.75rem",
                      color: msg.sender === "User" ? "#286BEF" : "#64748B",
                      marginTop: "4px",
                    }}
                  >
                    {msg.timestamp}
                  </Typography>
                  {msg.sender === "User" && <DoneAll fontSize="small" color={msg.delivered ? "primary" : "disabled"} />}
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
        <div ref={messageEndRef} />
      </MessageList>

      <ChatInputContainer>
        <TextField
          fullWidth
          variant="standard"
          placeholder="Write your response here..."
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
          InputProps={{ disableUnderline: true }}
          sx={{ flex: 1 }}
        />

        <Tooltip title="Voice message">
          <IconButton size="small">
            <Mic fontSize="small" />
          </IconButton>
        </Tooltip>

        <IconButton color="primary" onClick={handleSendMessage} disabled={!inputValue && files.length === 0} size="small">
          <Send fontSize="small" />
        </IconButton>
      </ChatInputContainer>
    </ChatBox>
  );
};

export default ChatBoxComponent;
