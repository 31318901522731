import React from "react";
import { Box, TextField, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AutoAwesomeSharpIcon from '@mui/icons-material/AutoAwesomeSharp';
import { Field, ErrorMessage } from "formik";
import { Label, FormikStyledTextField, ErrorText } from "./FormikStyledTextField"

const RoundContainer = styled(Paper)({
  padding: "16px",
  marginTop: "10px",
  borderRadius: "8px",
  backgroundColor: "#F7F9FD",
  border: "1px solid #E0E0E0",
});

const StyledTextField = styled(TextField)({
    backgroundColor: "#FFFFFF", 
    borderRadius: "8px",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D1D5DB", 
      },
      "&:hover fieldset": {
        borderColor: "#007AFF", 
      },
      "&.Mui-focused fieldset": {
        borderColor: "#007AFF", 
      },

      '& .MuiInputBase-input::placeholder':{
        fontSize:"14px",
      }
    },
  });
  
  interface InterviewRoundProps {
    roundNumber : number;
    roundIndex: number;
    onDelete: ()=> void;
  }
  const InterviewRound: React.FC<InterviewRoundProps> = ({ roundNumber, onDelete, roundIndex }) => {
  return (
    <RoundContainer>
         <Box display="flex" justifyContent="space-between" alignItems="center"marginBottom="10px">
        <Typography variant="body1" fontWeight="bold" sx={{ fontSize: "14px" }}>
        <AutoAwesomeSharpIcon sx={{fontSize:"20px" ,background:"#325CD6", color:"white", padding:"2px", borderRadius:"6px", marginRight:"5px"}}/>  Round {roundNumber} Interview
        </Typography>
        <IconButton onClick={onDelete}>
          <DeleteIcon/>
        </IconButton>
      </Box>
    
      <Box>
        <Label sx={{fontSize:"13px", color:"#333333"}}>Interview Title</Label>
        <Field sx={{backgroundColor: "#FFFFFF"}} component={FormikStyledTextField} name={`interviewRounds[${roundIndex}].title`} fullWidth size="small" placeholder="Enter Interview Title" />
        <ErrorMessage name={`interviewRounds[${roundIndex}].title`} component={ErrorText} />
      </Box>


      <Box sx={{ mt: 2 }}>
        <Label sx={{fontSize:"13px", color:"#333333"}}>Add Sample Questions or Hints to assist AI curate this interview. (Optional)</Label>
        <Field
          component={FormikStyledTextField}
          name={`interviewRounds[${roundIndex}].questions`}
          fullWidth
          placeholder="Add any sample questions you’d like to address at this interview stage..."
          multiline
          rows={4}
        />
        <ErrorMessage name={`interviewRounds[${roundIndex}].questions`} component={ErrorText} />
      </Box>

      <Box sx={{ mt: 2 }}>
        <Label sx={{fontSize:"13px" , color:"#333333"}}>Include a Criteria for Interview to assist our AI in evaluating candidates for you. (Optional)</Label>
        <Field
          component={FormikStyledTextField}
          name={`interviewRounds[${roundIndex}].criteria`}
          fullWidth
          placeholder="Mention different criteria for interview evaluation..."
          multiline
          rows={4}
        />
      </Box>
    </RoundContainer>
  );
};

export default InterviewRound;
